import React from 'react';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';

import { InfoCircle } from 'tabler-icons-react';

export const Warning = ({ open, setOpen }) => {
  return (
    <>
      <Modal className='Invalid-modal' opened={open} onClose={() => setOpen(false)} title='Warning'>
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>Final AVIC/HL cannot be negative.</Text>
            </Group>
          </Grid.Col>

          {/* <Grid.Col span={4} offset={8}>
            <Button onClick={() => setOpen(false)} variant='gradient' fullWidth>
              Ok
            </Button>
          </Grid.Col> */}
        </Grid>
      </Modal>
    </>
  );
};

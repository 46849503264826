import React, { createContext, useContext, useState } from 'react';
import { showAlert } from '../../../../utils/alerts';
import { axios } from '../../../../utils/axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { Warning } from './warning';

export const Context = createContext();

export const Provider = ({ children }) => {
  const { crNumber } = useParams();
  const [plantLoading, setPlantsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveLoading, setSaveLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  const activePage = searchParams.get('tab');

  const fetchAvicPlants = async (stage) => {
    try {
      setPlantsLoading(true);
      const { data } = await axios.get(`/costing-request/step/avic-step/${crNumber}`, {
        params: {
          stage,
        },
      });

      return data.data;
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      setPlantsLoading(false);
    }
  };

  const submit = async (stage, action, payload) => {
    try {
      if (payload?.country_level?.[0]?.final_avic < 0 && action === 'submit') {
        setWarning(true);
        return;
      }
      setSaveLoading(true);
      await axios.post(
        `/costing-request/step/avic-step/${crNumber}?stage=${stage}&action=${action}`,
        payload,
      );
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Context.Provider
      value={{
        plantLoading,
        setPlantsLoading,
        fetchAvicPlants,
        activePage,
        submit,
        saveLoading,
      }}
    >
      {children}

      {warning && <Warning open={warning} setOpen={setWarning} />}
    </Context.Provider>
  );
};

export const useAvic = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useAvic must be used within a Provider');
  }
  return context;
};

import React, { createContext, useCallback, useContext, useState } from 'react';
import { axios } from '../utils/axios';
import moment from 'moment';

const initialLoaders = {
  info: false,
  skus: false,
  plants: false,
};

const initialTabs = ['Overview'];

export const CostingRequestsContext = createContext();

export const CostingRequestsProvider = ({ children }) => {
  const [loaders, setLoaders] = useState(initialLoaders);
  const [info, setInfo] = useState({});
  const [skus, setSkus] = useState([]);
  const [plants, setPlants] = useState([]);
  const [countries, setCountries] = useState([]);
  const [visibleTabs, setVisibleTabs] = useState(initialTabs);
  const [crUserPermissions, setCrUserPermissions] = useState({});
  const [crStepStatus, setCrStepStatus] = useState({});
  const [crStatus, setCrStatus] = useState('');
  const [tabsLoader, setTabsLoader] = useState(false);
  const [userRoleMappings, setUserRoleMappings] = useState([]);

  const getIntCrUserRoleMappings = useCallback(async () => {
    try {
      const response = await axios.get('/user/v2/fetch/');
      const userMappings = response?.data?.data[0]?.int_user_role_mappings || [];
      setUserRoleMappings(userMappings);
      return userMappings; // Return for immediate use
    } catch (error) {
      console.error('Error fetching user role mappings:', error);
      setUserRoleMappings([]);
      return [];
    }
  }, []);
  const getRequestDetails = useCallback(
    async (id) => {
      setLoaders({ ...loaders, info: true });
      if (id) {
        axios
          .get(`/costing-request/record/${id}`)
          .then(
            async (res) => {
              const crInfo = res.data.data;
              await getUserPermissions(crInfo.costing_request_id);
              setCrStatus(crInfo?.IntCrStatus?.status);
              setInfo({
                ...crInfo,
                costing_year: crInfo.costing_year
                  ? moment(crInfo.costing_year).format('YYYY')
                  : null,
              });
            },
            (error) => {
              console.log(error);
            },
          )
          .finally(() => setLoaders({ ...loaders, info: false }));
      } else {
        setInfo({});
      }
    },
    [setInfo],
  );

  const getSkus = useCallback(() => {
    if (skus.length === 0) {
      setLoaders({ ...loaders, skus: true });
      axios
        .get('/costing-request/sku-data')
        .then(
          (res) => {
            const info = res?.data?.data;
            if (info) setSkus(info[0]);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoaders({ ...loaders, skus: false }));
    }
  }, [setSkus]);

  const getPlants = useCallback(() => {
    if (plants.length === 0) {
      setLoaders({ ...loaders, plants: true });
      axios
        .get('/costing-request/plant-data')
        .then(
          (res) => {
            setPlants(res.data);
            const uniqueCountries = [...new Set(res.data.map((info) => info.Country))].sort();
            setCountries(uniqueCountries);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoaders({ ...loaders, plants: false }));
    }
  }, [setPlants]);

  const tabsPermissions = async (crNumber) => {
    try {
      setVisibleTabs(initialTabs);
      setTabsLoader(true);
      const { data } = await axios.get(`/costing-request/tabs/${crNumber}`);
      setVisibleTabs((tabs) => {
        return [...tabs, ...data.data];
      });
    } catch (e) {
      console.log(e);
    } finally {
      setTabsLoader(false);
    }
  };

  const getUserPermissions = async (crNumber) => {
    try {
      const { data } = await axios.get(`/user/v2/access/${crNumber}`);
      setCrUserPermissions(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getStepStatus = async (crNumber) => {
    try {
      const { data } = await axios.get(`/costing-request/status/${crNumber}`);
      setCrStepStatus(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <CostingRequestsContext.Provider
      value={{
        loaders,
        info,
        skus,
        plants,
        getRequestDetails,
        getSkus,
        getPlants,
        countries,
        tabsPermissions,
        visibleTabs,
        crUserPermissions,
        crStepStatus,
        getStepStatus,
        crStatus,
        setInfo,
        tabsLoader,
        userRoleMappings,
        getIntCrUserRoleMappings,
      }}
    >
      {children}
    </CostingRequestsContext.Provider>
  );
};

export const useCrStatusCancelled = () => {
  const context = useContext(CostingRequestsContext);
  if (!context) {
    throw new Error('useCrStatusCancelled must be used within a CostingRequestsProvider');
  }
  return (context?.crStatus || '').toLowerCase() === 'cancelled';
};

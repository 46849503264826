import { Tooltip, Badge, Text } from '@mantine/core';
import { useState, useEffect } from 'react';
import { axios } from '../../../../utils/axios';

export const RefBadge = ({ crNumber }) => {
  const [refSku, setRefSku] = useState({
    sku: '--',
    sku_description: '--',
    prod_plant: '--'
  });

  const getSelectdRef = async () => {
    try {
      const { data } = await axios.get(
        `/costing-request/step/selected-ref-sku-selection/${crNumber}`,
      );
      console.log(data, "000000")
      setRefSku({
        sku: data?.[0].sku_details?.sku,
        sku_description: data?.[0].sku_details?.sku_description,
        prod_plant: data?.[0].sku_details?.plant,
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (crNumber) {
      getSelectdRef();
    }
  }, [crNumber]);
  return (
    <>
        <Badge style={{ color: 'black' }}>
          <Text size={16}>
            Ref : {refSku?.sku} | {refSku?.prod_plant} | {refSku?.sku_description}
          </Text>
        </Badge>
    </>
  );
};

import * as yup from 'yup';

export const FormSchema = yup.object().shape({
  type_of_request: yup.string().required('type_of_request'),
  urgency: yup.string().required('urgency'),
  requestorName: yup.string().required('requestorName'),
  types_of_good: yup.string().required('types_of_good'),
  types_of_sku: yup
    .string()
    .required('types_of_sku')
    .when('type_of_request', {
      is: 'Transfer Pricing',
      then: () => yup.string().nullable(),
    }),
  sku_data: yup.array().min(1, 'sku_data').required('sku_data'),

  producing_plant_data: yup
    .array()
    .min(1, 'producing_plant_data')
    .max(5, 'producing_plant_data')
    .required('producing_plant_data'),
  selling_plant_data: yup
    .array()
    .nullable()
    .when(['types_of_good', 'type_of_request'], ([types_of_good, type_of_request]) => {
      if (types_of_good === 'Empties' && type_of_request === 'Transfer Pricing') {
        return yup
          .array()
          .min(1, 'selling_plant_data')
          .max(5, 'selling_plant_data')
          .required('selling_plant_data');
      }
    }),
  buying_plant_data: yup
    .array()
    .min(1, 'buying_plant_data')
    .max(15, 'buying_plant_data')
    .required('buying_plant_data')
    .when('type_of_request', {
      is: 'Costing',
      then: () => yup.array().nullable(),
    }),
  sales_district_of_buying_country: yup
    .string()
    .required('sales_district_of_buying_country')
    .when('type_of_request', {
      is: 'Costing',
      then: () => yup.string().nullable(),
    }),
  stock_taking_order_type: yup
    .string()
    .required('stock_taking_order_type')
    .when('type_of_request', {
      is: 'Costing',
      then: () => yup.string().nullable(),
    }),
});

import { Space, Tabs } from '@mantine/core';
import { tabStyles } from '../../../../utils/helpers';
import FobSetup from './fobSetup';
import { ProjectOverview } from '../projectOverview';
import { useSearchParams } from 'react-router-dom';
import { TpErpSetup } from './tpErpSetup';
import { UpdateSKU } from './updateSku';
import { UpdateVLC } from './updateVlc';
import { CreateTPFlow } from './createTpFlow';
import FinalizeTPFlow from './finalizeTpFlow';
import { useContext } from 'react';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';

const TpSetups = ({ activeTab, logsModal, setLogsModal }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTab = searchParams.get('subtab') || 'updateSku';
  const { visibleTabs } = useContext(CostingRequestsContext);
  const tpSetupTabs = [
    {
      name: 'Update SKU ',
      value: 'updateSku',
      validator: 'Update SKU',
      component: (props) => <UpdateSKU {...props} />,
    },
    {
      name: 'Update VLC',
      value: 'updateVlc',
      validator: 'Update VLC',
      component: (props) => <UpdateVLC {...props} />,
    },
    {
      name: 'Create TP Flow',
      value: 'createTpFlow',
      validator: 'Create TP',
      component: (props) => <CreateTPFlow {...props} />,
    },
    {
      name: 'FOB Setup',
      value: 'fobSetup',
      validator: 'FOB Setup',
      component: (props) => <FobSetup {...props} />,
    },
    {
      name: 'Finalize TP Flow',
      value: 'finalizeTpFlow',
      validator: 'Finalize TP',
      component: (props) => <FinalizeTPFlow {...props} />,
    },
    {
      name: 'TP ERP Setup',
      value: 'tpErpSetup',
      validator: 'TP ERP Setup',
      component: (props) => <TpErpSetup {...props} />,
    },
  ];
  return (
    <>
      <Space h='sm' />
      <Tabs
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
        onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
        value={activeSubTab}
        keepMounted={false}
      >
        <Tabs.List>
          {tpSetupTabs.map(
            (tab, index) =>
              (visibleTabs.includes(tab.validator) || !tab.validator) && (
                <Tabs.Tab value={tab.value} key={`tp_${index}`} className='menu-tabs'>
                  {tab.name}
                </Tabs.Tab>
              ),
          )}
        </Tabs.List>
        {tpSetupTabs.map(
          (tab, index) =>
            (visibleTabs.includes(tab.validator) || !tab.validator) && (
              <>
                <Tabs.Panel value={tab.value} key={`tp_panel_${index}`}>
                  <ProjectOverview />
                  <Space h='sm' />
                  {tab.component({ activeTab, activeSubTab, logsModal, setLogsModal })}
                </Tabs.Panel>
              </>
            ),
        )}
      </Tabs>
    </>
  );
};

export default TpSetups;

import React from 'react';
import { Grid, Text, Button, Space, Container, ActionIcon, Anchor } from '@mantine/core';
import { CustomSelect, CustomTextInput, StyledText } from '../../styles';
import { staticData } from './static';
import { CustomTextArea as Comment } from '../../../../common/inputs/CustomTextArea';
import { PreviewTable } from '../table/previewTable';
import { skuColumns } from '../table/skusTable';
import { plantColumns } from '../table/plantsTable';
import { IconEdit } from '@tabler/icons-react';
import { useState, useContext } from 'react';
import { DownloadIcon } from '@modulz/radix-icons';
import { keyframes } from '@emotion/react';
import { RfsContext } from '../../../../contexts/RfsContext';
import { PriorityModal } from './priority';

const Divider = () => (
  <Grid.Col span={12}>
    <Space h='sm' />
  </Grid.Col>
);

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

const Label = ({ withAsterisk, children, withEdit, onClick, color }) => (
  <>
    <StyledText fz='md' c={color}>
      {children}{' '}
      {withAsterisk && (
        <span className='mantine-103svbs mantine-InputWrapper-required mantine-TextInput-required'>
          {' '}
          *
        </span>
      )}
      {withEdit && (
        <ActionIcon variant='subtle' onClick={onClick}>
          <IconEdit size='1rem' />
        </ActionIcon>
      )}
    </StyledText>
  </>
);

const SelectPlantButton = ({ label, color, onClick, withAsterisk = false, offset, data }) => {
  const columns = label === 'Select SKU' ? skuColumns : plantColumns(label.split(' ')[1]);

  return (
    <Grid.Col md={6}>
      <Grid align='center'>
        <Grid.Col span={4}>
          <Label
            withAsterisk={withAsterisk}
            withEdit={data.length > 0}
            onClick={onClick}
            color={color}
          >
            {label}
          </Label>
        </Grid.Col>
        {data.length === 0 ? (
          <Grid.Col span={6} offset={2}>
            <Button fullWidth color={color} onClick={onClick}>
              {label}
            </Button>
          </Grid.Col>
        ) : (
          <Grid.Col span={12}>
            <PreviewTable label={label} data={data} columns={columns} />
          </Grid.Col>
        )}
      </Grid>
    </Grid.Col>
  );
};

export const Form = ({ values, onChange, setPlantSkuModal, errors }) => {
  const { urgency, typeOfGoods, typeOfSku, orderType, typeOfRequest } = staticData;
  const [priorityModalOpen, setPriorityModalOpen] = useState(false);
  const { downloadFile } = useContext(RfsContext);
  return (
    <>
      <Space h='md' />
      <Container style={{ margin: 0, borderLeft: '2px solid rgb(244, 224, 15' }}>
        <Text fz='md' fw={700}>
          GENERAL DETAILS
        </Text>
      </Container>
      <Space h='md' />
      <Grid>
        <Grid.Col md={6}>
          <CustomTextInput
            disabled
            value={values.requestorName}
            placeholder='Requester Name'
            label='Requester Name'
            withAsterisk
            error={errors['requestorName']}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <CustomTextInput
            disabled
            placeholder='Requester Team'
            label='Requester Team'
            withAsterisk
            error={errors['requestorTeam']}
          />
        </Grid.Col>
        <Divider />
        <Grid.Col md={6}>
          <div style={{ position: 'relative' }}>
            <Anchor
              component='button'
              onClick={() => setPriorityModalOpen(true)}
              sx={{
                textDecoration: values.urgency === 'Critical' ? 'underline' : 'none',
                fontWeight: values.urgency === 'Critical' ? 'bold' : 'normal',
                color: '#e5b611',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                right: 0,
                animation: values.urgency === 'Critical' ? `${shake} 1s linear` : 'none',
              }}
            >
              <DownloadIcon size={16} style={{ marginRight: '8px' }} />
              Priority Matrix
            </Anchor>
            <CustomSelect
              name='urgency'
              value={values.urgency}
              label='Urgency'
              placeholder='Select'
              data={urgency}
              onChange={(value) => onChange({ ...values, urgency: value })}
              error={errors['urgency']}
              withAsterisk
            />
          </div>
        </Grid.Col>
        <Grid.Col md={6}>
          <CustomSelect
            name='type_of_request'
            value={values.type_of_request}
            label='Type of request'
            placeholder='Select'
            data={typeOfRequest}
            onChange={(value) => onChange({ ...values, type_of_request: value })}
            error={errors['type_of_request']}
            withAsterisk
          />
        </Grid.Col>
        <Divider />

        <Grid.Col md={6}>
          <CustomSelect
            value={values.types_of_good}
            name='types_of_good'
            label='Type of Goods'
            placeholder='Select'
            data={typeOfGoods}
            withAsterisk
            onChange={(value) => {
              const updatedValues = { ...values, types_of_good: value };
              updatedValues.types_of_sku = '';
              onChange(updatedValues);
            }}
            error={errors['types_of_good']}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <CustomSelect
            value={values.types_of_sku}
            name='types_of_sku'
            label='Type of SKU'
            placeholder='Select'
            data={
              values.types_of_good === 'Finished Goods'
                ? ['Filling', 'Repack']
                : values.types_of_good === 'Road Tanker'
                ? ['Road Tanker']
                : typeOfSku
            }
            withAsterisk={values.type_of_request !== 'Transfer Pricing'}
            onChange={(value) => onChange({ ...values, types_of_sku: value })}
            error={errors['types_of_sku']}
          />
        </Grid.Col>

        <Divider />

        <SelectPlantButton
          label='Select SKU'
          color={errors['sku_data'] ? 'red' : 'dark'}
          onClick={() => setPlantSkuModal({ open: true, type: 'sku', title: 'Select SKU' })}
          data={values?.['sku_data']}
          withAsterisk
        />
        <SelectPlantButton
          label='Select Production Plant'
          color={errors['producing_plant_data'] ? 'red' : 'dark'}
          onClick={() =>
            setPlantSkuModal({ open: true, type: 'plant', title: 'Select Production Plant' })
          }
          withAsterisk={true}
          offset={2}
          data={values?.['producing_plant_data']}
        />
        <Divider />
        <SelectPlantButton
          label='Select Selling Plant'
          color={errors['selling_plant_data'] ? 'red' : 'dark'}
          onClick={() =>
            setPlantSkuModal({ open: true, type: 'plant', title: 'Select Selling Plant' })
          }
          data={values?.['selling_plant_data']}
          withAsterisk={
            values.types_of_good === 'Empties' && values.type_of_request === 'Transfer Pricing'
          }
        />
        <SelectPlantButton
          label='Select Buying Plant'
          color={errors['buying_plant_data'] ? 'red' : 'dark'}
          onClick={() =>
            setPlantSkuModal({ open: true, type: 'plant', title: 'Select Buying Plant' })
          }
          offset={2}
          data={values?.['buying_plant_data']}
          withAsterisk={values.type_of_request !== 'Costing'}
        />

        <Divider />
        <Grid.Col md={6}>
          <CustomSelect
            value={values.stock_taking_order_type}
            name='stock_taking_order_type'
            label='Stock Taking Order Type'
            placeholder='Select'
            data={orderType}
            withAsterisk={values.type_of_request !== 'Costing'}
            onChange={(value) => onChange({ ...values, stock_taking_order_type: value })}
            error={errors['stock_taking_order_type']}
            disabled={values.type_of_request === 'Costing'}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <CustomTextInput
            value={values.sales_district_of_buying_country}
            name='sales_district_of_buying_country'
            label='Sales District Code of the Buying Country'
            placeholder='Select'
            disabled
            withAsterisk={values.type_of_request !== 'Costing'}
            onChange={(value) => onChange({ ...values, sales_district_of_buying_country: value })}
            error={errors['sales_district_of_buying_country']}
          />
        </Grid.Col>

        <Divider />
        <Grid.Col md={6}>
          <Comment
            name='comment'
            label='Any Additional Comments'
            maxLength={5000}
            onChange={(value) => onChange({ ...values, comment: value })}
            value={values?.comment || ''}
          />
        </Grid.Col>
      </Grid>

      <PriorityModal
        priorityModalOpen={priorityModalOpen}
        setPriorityModalOpen={setPriorityModalOpen}
        downloadFile={downloadFile}
      />
    </>
  );
};

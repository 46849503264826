import { useState } from 'react';
import { Tabs } from '@mantine/core';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { tabStyles } from '../../../../utils/helpers';
import VLCSetup from './vlcSetup';
import AvicSetup from './avicSetup';

const CostConditionCreation = ({ activeTab, setLogsModal }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeSubTab = searchParams.get('subtab') || 'avicSetup';

  const tabs = [
    {
      name: 'AVIC Setup',
      value: 'avicSetup',
      component: (props) => <AvicSetup {...props} />,
    },
    {
      name: 'VLC Setup',
      value: 'vlcSetup',
      component: (props) => <VLCSetup {...props} />,
    },
  ];
  return (
    <>
      <Tabs
        keepMounted={false}
        value={activeSubTab}
        onTabChange={(value) => setSearchParams({ tab: activeTab, subtab: value })}
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
      >
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab value={tab.value} key={index} className='menu-tabs'>
              {tab.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.map((tab, index) => (
          <Tabs.Panel value={tab.value} key={index}>
            {tab.component({
              activeSubTab,
              activeTab,
              setLogsModal,
            })}
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};

CostConditionCreation.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default CostConditionCreation;

import { useEffect, useMemo, useState, useContext } from 'react';
import { Checkbox, Button } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import MantineTable from '../../../../common/table/mantine';
import { Status } from '../../styles';
import PccModal from './pccModal';
import { showAlert } from '../../../../utils/alerts';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import moment from 'moment';

const PccSetup = ({ activeTab, setLogsModal }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { crNumber } = useParams();
  const [modal, setModal] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const { crUserPermissions } = useContext(CostingRequestsContext);

  useEffect(() => {
    if (activeTab === 'pccSetup') {
      fetch();
    }
  }, [activeTab]);
  const handleCheckBox = (val, id) => {
    setCheckboxValue(val);
    setSelectedId(id);
    if (val) {
      setModal(true);
    }
  };
  const handleSubmitPcc = async () => {
    setLoading(true);
    const payload = { row_id: selectedId };
    try {
      await axios.post(`/costing-request/costing_pcc_setup/${crNumber}`, payload);
      showAlert('', 'success');
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      setModal(false);
      fetch();
      setLoading(false);
    }
  };
  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/costing-request/costing_pcc_setup/${crNumber}`);
      const apiData = response?.data?.data;
      const updatedData = transformData(apiData);
      setData(updatedData);
    } catch (e) {
      console.log(e);
      showAlert(e, 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setData((prevData) => {
      const updatedData = prevData.map((ele) =>
        ele.id === selectedId ? { ...ele, pcc_setup: false } : ele,
      );
      return updatedData;
    });
    setCheckboxValue(null);
    setSelectedId(null);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const transformData = (pccData) => {
    pccData.map((ele) => {
      ele.sku_excel = ele?.int_cr_sku_selector?.sku;
      ele.sku_desc_excel = ele?.int_cr_sku_selector.sku_description;
      ele.producing_plant_excel = ele?.int_cr_producing_plant.producing_plant;
      ele.pcc_setup_excel = ele?.pcc_setup ? 'Yes' : 'No';
      ele.pcc_status_excel = ele?.int_cr_pcc_setup_record?.status;
      ele.user_excel = ele?.costing_ticket_assigned?.name;
      ele.submitted_on_excel = moment(ele?.finish_date).isValid()
        ? moment(ele?.finish_date).format('DD-MM-YYYY')
        : null;
    });
    return pccData;
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: 'sku_excel',
        header: 'SKU No',
      },
      {
        accessorKey: 'sku_desc_excel',
        header: 'SKU Desc',
      },
      {
        accessorKey: 'producing_plant_excel',
        header: 'Producing Plant',
      },
      {
        id: 'pcc_setup_excel',
        header: 'Is PCC Setup in ERP?',
        Cell: ({ row }) => {
          const prodPlant = row.original?.int_cr_producing_plant?.producing_plant;
          const enable =
            crUserPermissions[String(prodPlant)]?.['standard/transfer_pricing'].is_accessible &&
            row.original?.int_cr_pcc_setup_record?.status === 'In Progress' &&
            !row.original?.pcc_setup;
          return (
            <div>
              <Checkbox
                disabled={!enable}
                value={row?.original?.pcc_setup}
                onChange={(evt) =>
                  handleCheckBox(
                    evt.currentTarget.checked,
                    row.original?.int_cr_pcc_setup_record?.id,
                  )
                }
              />
            </div>
          );
        },
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }) => {
          return row.original?.int_cr_pcc_setup_record?.status ? (
            <Status status={row.original?.int_cr_pcc_setup_record?.status} />
          ) : null;
        },
      },
      {
        id: 'logs',
        header: 'Logs',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={() =>
              setLogsModal({
                open: true,
                logs: row.original.IntCrStepStatusLogs,
                type: 'PCC Setup',
              })
            }
          >
            View Logs
          </Button>
        ),
      },
      {
        accessorKey: 'user_excel',
        header: 'FP&A GCC SME',
      },
      {
        accessorKey: 'submitted_on_excel',
        header: 'Submitted On',
      },
    ],
    [handleClose, data, setData],
  );
  const initialState = {
    columnPinning: {
      left: [],
      right: [],
    },
    sorting: [
      {
        id: 'int_cr_sku_selector.sku_description',
        desc: false,
      },
    ],
  };
  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data.filter((ele) => ele)}
        key={refreshKey}
        enablePinning
        initialState={initialState}
        unique={'id'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        pageSize={10}
        enableSelectAll={false}
        csvFilename={`pcc_setup_${moment().format('YYYY-MM-DD')}`}
      />
      <PccModal
        modal={modal}
        handleSubmitPcc={handleSubmitPcc}
        setModal={setModal}
        selectedId={selectedId}
        checkboxValue={checkboxValue}
        handleClose={handleClose}
      />
    </>
  );
};

export default PccSetup;

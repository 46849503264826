
import React from 'react';
import { Modal, Text, Grid, Button, Textarea } from '@mantine/core';

const PccModal = ({ modal, handleSubmitPcc, setModal, selectedId, checkboxValue, handleClose }) => {
  const handleCancel = () => {
    setModal(false);
    handleClose();
  };
  const handleSubmit = () => {
    handleSubmitPcc();
  };
  return (
    <Modal
      opened={modal}
      onClose={handleCancel}
      title='PCC Setup in ERP Conformation'
      size='lg'
      closeOnClickOutside={false}
      styles={{
        title: { fontWeight: 600 },
        body: { minHeight: '200px' },
      }}
    >
      <Text color='black' fw={400} size='md' mb='md'>
        Are you sure ?
      </Text>
      <Textarea fw={250} size='sm' mb='sm' color='black' readOnly>
        Please ensure that the Product Cost Collector is setup in ERP before you check this box.
      </Textarea>
      <div style={{ marginTop: '40px' }}>
        <Grid gutter='md'>
          <Grid.Col span={8}></Grid.Col>
          <Grid.Col span={4}>
            <Button radius='md' variant='gradient' fullWidth onClick={handleSubmit}>
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    </Modal>
  );
};
export default PccModal;



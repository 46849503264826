import { useEffect, useState, useContext } from 'react';
import VLCTable from './table';
import { axios } from '../../../../../utils/axios';
import { showAlert } from '../../../../../utils/alerts';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

const VLCSetup = ({ activeSubTab, activeTab, setLogsModal }) => {
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { getIntCrUserRoleMappings, userRoleMappings } = useContext(CostingRequestsContext);

  useEffect(() => {
    const fetchMappings = async () => {
      await getIntCrUserRoleMappings();
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (
      activeTab === 'costConditionCreate' &&
      activeSubTab === 'vlcSetup' &&
      userRoleMappings.length > 0
    ) {
      fetch();
    }
  }, [activeTab, activeSubTab, userRoleMappings]);

  const getAllowedCountries = (role) => {
    if (role === 'fpna') {
      let countries = userRoleMappings
        .filter((role) => role.country && role.is_active && role.int_role?.name === 'GCC FP&A')
        .map((role) => role.country);

      countries = [...new Set(countries)];
      return countries;
    }

    let logistics = userRoleMappings
      .filter(
        (role) =>
          role.country && role.is_active && role.int_role?.alias === 'logistics_fpna_analyst',
      )
      .map((role) => role.country);

    logistics = [...new Set(logistics)];
    return logistics;
  };
  const checkActive = (status, country, userType) => {
    const allowedCountries = getAllowedCountries(userType);
    const res =
      String(status).toLocaleLowerCase() === 'in progress' && allowedCountries.includes(country);

    return res ?? false;
  };

  const transformData = (data) => {
    const newData = data.map((ele) => {
      ele.sku = ele?.IntCrConditionRecordLogs?.[0]?.material;
      ele.sku_desc = ele?.IntCrConditionRecordLogs?.[0]?.material_description;
      ele.sales_org = ele?.IntCrConditionRecordLogs?.[0]?.sales_org;
      ele.distribution_channel = ele?.IntCrConditionRecordLogs?.[0]?.distribution_channel;
      ele.condition_type = ele?.IntCrConditionRecordLogs?.[0]?.condition_type;
      ele.key_combination = ele?.IntCrConditionRecordLogs?.[0]?.key_combination;
      ele.costPerHL = parseFloat(ele?.IntCrConditionRecordLogs?.[0]?.cost_per_hl).toFixed(2) || 0;
      ele.costPerHundred = parseFloat(ele?.IntCrConditionRecordLogs?.[0]?.cost_per_100hl).toFixed(2) || 0;
      ele.currency = ele?.IntCrConditionRecordLogs?.[0]?.currency_key;
      ele.uom = ele?.IntCrConditionRecordLogs?.[0]?.unit_of_measure;
      ele.unit = ele?.IntCrConditionRecordLogs?.[0]?.per;
      ele.valid_from = moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_from).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_from).format('DD-MM-YYYY')
        : null;
      ele.valid_to = moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_to).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_to)
            .utc()
            .startOf('day')
            .format('DD-MM-YYYY')
        : null;
      ele.submittedOn = moment(ele?.IntCrConditionRecordLogs?.[0]?.updatedAt).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.updatedAt).format('DD-MM-YYYY')
        : '';
      ele.completedOn = moment(ele?.IntCrConditionRecordLogs?.[0]?.completed_on).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.completed_on).format('DD-MM-YYYY')
        : '';
      ele.fpna_gcc_sme = ele?.FPnA_sme?.name;
      ele.logisticsFpna = ele?.LogisticsFpNa_sme?.name;
      return ele;
    });
    return newData;
  };
  const checkActiveFpna = (status, country, value) => {
    const allowedCountries = getAllowedCountries('fpna');
    const res =
      String(status).toLocaleLowerCase() === 'in progress' &&
      allowedCountries.includes(country) &&
      (parseFloat(value) ? parseFloat(value) : 0.0) !== 0;

    return res ?? false;
  };
  const fetch = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`costing-request/step/vlc_setup/${crNumber}`);
      const apiData = transformData(res?.data?.data);
      setData(apiData);
    } catch (e) {
      console.log('errorrr', e);
    } finally {
      setLoading(false);
    }
  };
  const handleCostChange = (val, id) => {
    setData((prevData) =>
      prevData.map((ele) => {
        if (ele?.id === id) {
          ele.costPerHL = val;
        }
        return ele;
      }),
    );
  };
  const submitCostChange = async (val, id, sales_org) => {
    setLoading(true);
    const payload = {
      step_id: id,
      amount: val,
    };

    try {
      const res = await axios.post(`costing-request/step/vlc_setup/${crNumber}`, payload);
      if (res?.data?.success) {
        showAlert({ message: `VLC Setup successfully in Sales Org ${sales_org}` }, 'success');
      }
    } catch (e) {
      console.log(e);
      showAlert(
        { message: `VLC Setup Failed in Sales Org ${sales_org}. Please check logs` },
        'error',
      );
    } finally {
      fetch();
      setLoading(false);
    }
  };
  const handleRefresh = async (id, sales_org) => {
    setLoading(true);
    const payload = { step_id: id };
    try {
      const res = await axios.post(`costing-request/step/vlc_setup/retry/${crNumber}`, payload);
      if (res?.data.success) {
        showAlert({ message: `VLC Setup successfully in Sales Org ${sales_org}` }, 'success');
      }
    } catch (e) {
      showAlert({ message: `VLC Setup failed in ${sales_org} Please check logs` }, 'error');
    } finally {
      fetch();
      setLoading(false);
    }
  };
  return (
    <>
      <VLCTable
        handleRefresh={handleRefresh}
        data={data}
        handleCostChange={handleCostChange}
        loading={loading}
        logsHeader={'VLC Setup'}
        setLogsModal={setLogsModal}
        submitCostChange={submitCostChange}
        setData={setData}
        checkActive={checkActive}
        checkActiveFpna={checkActiveFpna}
        csvFilename={`cost_condtion_creattion_VLC_${moment().format('YYYY-MM-DD')}`}
      />
    </>
  );
};
export default VLCSetup;

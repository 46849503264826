import React from 'react';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { Space, Tabs } from '@mantine/core';
import { tabStyles } from '../../../utils/helpers';
import { AdminProvider } from '../../../contexts/AdminContext';
import RfsLayout from '../../../components/layouts/RfsLayout';
import Users from './users';
import PendingUsers from './pendingUsers';
import { useSearchParams } from 'react-router-dom';

function ManageHub() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'pending';
  return (
    <RfsLayout>
      <AdminProvider>
        <ContentPaper page='admin-console'>
          <Space h='md' />
          <h5 className='sectionHeadings'>Manage Hub</h5>
          <Space h='md' />

          <Tabs
            variant='unstyled'
            value={tab}
            onTabChange={(value) => setSearchParams({ tab: value })}
            styles={tabStyles}
            color='primary'
            defaultValue={'pending'}
          >
            <Tabs.List>
              <Tabs.Tab value='pending'>Pending Requests</Tabs.Tab>
              <Tabs.Tab value='existing'>Existing Users</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='pending'>
              <PendingUsers activeTab={tab} />
            </Tabs.Panel>

            <Tabs.Panel value='existing'>
              <Users activeTab={tab} />
            </Tabs.Panel>
          </Tabs>
        </ContentPaper>
      </AdminProvider>
    </RfsLayout>
  );
}

export default ManageHub;

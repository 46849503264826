import moment from 'moment';

export const getPayload = (plant, country, general) => {
  return {
    sku_id: general.skuId,
    step_id: general.stepId,
    plant_level: plant,
    country_level: [
      {
        ...country,
        final_avic: calculateFinalAvic(plant, country),
        w_avg: calculateWeightedAverage(plant),
        manual_adjustment:
          typeof country.manual_adjustment === 'string' && country.manual_adjustment.length === 0
            ? null
            : country.manual_adjustment,
        perf_plug:
          typeof country.perf_plug === 'string' && country.perf_plug.length === 0
            ? null
            : country.perf_plug,
        price_plug:
          typeof country.price_plug === 'string' && country.price_plug.length === 0
            ? null
            : country.price_plug,
      },
    ],
  };
};

export const createPlantObject = (plantLevels = []) => {
  const plantObject = {};

  plantLevels.forEach((plant) => {
    if (!plantObject[plant.open_plant]) {
      plantObject[plant.open_plant] = { ...plant };
    }
  });

  return plantObject;
};

export const getGeneralInfo = (plantLevels = []) => {
  if (!plantLevels.length) return {};

  const { sku_id, step_id } = plantLevels[0];
  return { skuId: sku_id, stepId: step_id };
};

const calculateFinalAvic = (plants, data) => {
  const { perf_plug = 0, price_plug = 0, manual_adjustment = 0 } = data;
  const wa = calculateWeightedAverage(plants);

  const total = parseFloat(wa) + Number(perf_plug) + Number(price_plug) + Number(manual_adjustment);
  return parseFloat(total || 0).toFixed(2);
};

const calculateTotalVolume = (data) => {
  return parseFloat(data.reduce((sum, plant) => sum + plant.volume, 0)).toFixed(2);
};

const calculateTotalAvic = (data) => {
  return parseFloat(data.reduce((sum, plant) => sum + plant.lvic * plant.volume, 0)).toFixed(2);
};

const calculateWeightedAverage = (data) => {
  const totalVolume = calculateTotalVolume(data);
  const totalAvic = calculateTotalAvic(data);
  const wa = totalAvic / totalVolume;
  return parseFloat(wa || 0).toFixed(2);
};

export const getFooterInfoToExport = (plantsData, footerData, info, page) => {
  return {
    sku: info?.sku_data?.[0]?.sku || 'N/A',
    sku_description: info?.sku_data?.[0]?.sku_description || 'N/A',
    open_plant: '',
    volume: calculateTotalVolume(plantsData),
    lvic: '',
    'W.Avg VIC/HL': calculateWeightedAverage(plantsData),
    'Perf Plug': footerData?.perf_plug,
    'Price Plug': footerData?.price_plug,
    'Other Adj': footerData?.manual_adjustment,
    'Final AVIC/HL': calculateFinalAvic(plantsData, footerData),
    'Ref SKU': footerData.ref_sku,
    'Ref SKU Desc.': footerData.ref_sku_description,
    'Ref AVIC/HL': footerData.ref_sku_avic,
    Curr: footerData.ref_avic_cur,
    [page === 'avicSubmission' ? 'FP&A GCC SME' : 'FP&A Brewery Head']: footerData.updated_by,
    'Submitted On':
      footerData.submitted_on && moment(footerData?.submitted_on).isValid()
        ? moment(footerData.submitted_on).format('YYYY-MM-DD')
        : 'N/A',
  };
};

export const hasPermission = (step, permissions) => {
  const steps = {
    avicSubmission: 'standard/transfer_pricing',
    avicFinalization: 'fpna_brewery_heads',
  };

  const permission = permissions.findIndex((p) => p?.[steps[step]].is_accessible);

  return permission > -1;
};

import moment from 'moment';
import { useEffect, useMemo, useState,useContext } from 'react';
import { Button } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Status } from '../../styles';
import styled from '@emotion/styled';
import { IconRefresh, IconTrash,IconReload } from '@tabler/icons-react';
import { showAlert } from '../../../../utils/alerts';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';

export const Costing = ({ setLogsModal, activeTab }) => {
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const { crUserPermissions } = useContext(CostingRequestsContext);
  const CenterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

  useEffect(() => {
    if (activeTab === 'costingRelease') fetch();
  }, [activeTab]);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/step/cost-release/${crNumber}`);
      const data = response.data.data.map((item) => {
        const status = (item.status || '').toLowerCase();
        return {
          sku_number: item.int_cr_sku_selector.sku,
          sku_description: item.int_cr_sku_selector.sku_description,
          producing_plant: item.int_cr_producing_plant.producing_plant,
          status:
           status === 'not started'
              ? 'Not Started'
              : status === 'closed'
              ? 'Completed'
              : 'Pending',
          logs: item.IntCrStepStatusLogs,
          assignedTo: item.costing_ticket_assigned?.name || 'N/A',
          spoc: item.step_assigned?.name || 'N/A',
          step_id: item.step_id ,
          sku_id: item.sku_id,
          producing_plant_id: item.producing_plant_id
        };
      });
      setSkus(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async ( producingPlantId, skuId, stepId,plant) => {
    try {
      setLoading(true);
      const payload = {
        costing_request_id: crNumber,
        producing_plant_id: producingPlantId,  
        sku_id: skuId,  
        step_id: stepId,  
      };
      const response = await axios.post(`/costing-request/cost-release-retry/${crNumber}`, payload);
  
      if (response.status === 200) {
        showAlert(
          {
            message: `Cost Released successfully in plant ${plant}`,
          },
          'success'
        );
      } else {
        throw new Error(response.data.message || 'Failed to refresh costing request');
      }

      await fetch();
    } catch (error) {
      showAlert(
        {
          message: `Cost Release failed in plant ${plant}. Please check logs`,
        },
        'error'
      );
    }
   finally {
    setLoading(false);
  }
  };
  


const fetchLogs = async (plant, sku) => {
  try {
    const url = `/costing-request/cost-release-logs/${crNumber}?plant=${encodeURIComponent(plant)}&sku=${encodeURIComponent(sku)}`;
    const response = await axios.get(url);
    if (response.status !== 200) {
      throw new Error('Failed to fetch logs');
    }
    return response.data.data;
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
};



const columns = useMemo(
  () => [
    {
      accessorKey: 'action',
      header: 'Action',
      Cell: ({ row }) => {
        const producingPlantId = row.original?.producing_plant_id;
        const skuId = row.original?.sku_id;
        const stepId = row.original?.step_id;
        const crNumber = row.original?.crNumber;
        const plant =row.original?.producing_plant;
        const status = row.original?.status
        const hasRefreshAccess =crUserPermissions[String(plant)]?.['standard/transfer_pricing']
                                ?.is_accessible;
        const isDisabled = status === 'Completed' || status === 'Not Started' || !hasRefreshAccess;
        return (
          <Button
            variant="subtle"
            color="dark"
            size="sm"
            style={{ width: '100%' }}
            onClick={() => handleRefresh( producingPlantId, skuId, stepId,plant, status)}
            disabled={isDisabled}
          >
            Retry
          </Button>
        );
      },
    },
    {
      accessorKey: 'sku_number',
      header: 'SKU No',
    },
    {
      accessorKey: 'sku_description',
      header: 'SKU Desc',
    },
    {
      accessorKey: 'producing_plant',
      header: 'Producing Plant',
    },
    {
      id: 'status',
      header: 'Status',
      Cell: ({ row }) => <Status status={row.original.status} />,
    },
    {
      id: 'logs',
      header: 'Logs',
      Cell: ({ row }) => {
        const crNumber = row.original?.crNumber;
        const sku = row.original?.sku_number;
        const plant = row.original?.producing_plant;
        return (
          <Button
            color="dark"
            onClick={async () => {
              try {
                const logs = await fetchLogs( plant, sku);
                setLogsModal({
                  open: true,
                  logs: logs,
                  type: 'Costing Release Check',
                });
              } catch (error) {
                console.error('Error fetching logs:', error);
              }
            }}
          >
            View Logs
          </Button>
        );
      },
    },
    {
      accessorKey: 'assignedTo',
      header: 'FP&A GCC SME',
    },
  ],
  [skus]
);

return (
  <>
    <MantineTable
      columns={columns}
      initialData={skus}
      unique="sku" 
      enableRowSelection={false}
      loading={loading}
      showSelectedToggle={false}
      hideSelectColumn={true}
      showResetAll={false}
      enableExport={true}
      csvFilename={`costing_release_check_${moment().format('YYYY-MM-DD')}`}
      pageSize={10}
    />
  </>
);
};


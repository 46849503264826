import React, { createContext, useContext, useState } from 'react';
import { saveAs } from 'file-saver';
import { EXTENSIONS } from '../../../../constants';
import { axios } from '../../../../utils/axios';
import { MAX_ATTACHMENTS } from '../../constants';
import { attachmentChunks } from '../../../../utils/helpers';
import { useParams, useSearchParams } from 'react-router-dom';
import { AttachmentsModal } from '../../../../common/attachments';
import { showAlert } from '../../../../utils/alerts';

const initialAttachmentState = {
  open: false,
  plantId: null,
  attachments: [],
  existingAttachments: [],
};

export const stepsKeys = {
  vicSubmission: 'vic-submission',
  vicApproval: 'vic-approval',
  avicSubmission: 'avic-submission',
  avicFinalization: 'avic-finalization',
};

export const Context = createContext();

export const AttachmentsProvider = ({ children }) => {
  const { crNumber } = useParams();
  const [attachmentModal, setAttachmentModal] = useState(initialAttachmentState);
  const [attachmentLoading, setAttachmentsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [disableUploads, setDisableUploads] = useState(true);

  const activePage = searchParams.get('tab');

  const fetchAttachments = async (stepId) => {
    try {
      if (!stepId) return;
      setAttachmentsLoading(true);
      const { data } = await getAttachmentsRequest(stepId, stepsKeys[activePage]);

      setAttachmentModal({
        open: false,
        plantId: stepId,
        attachments: [],
        existingAttachments: data?.uploads || [],
      });
      return data;
    } catch (e) {
      console.log(e);
    } finally {
      setAttachmentsLoading(false);
    }
  };

  const getAttachmentsRequest = async (stepId, referenceType) => {
    return await axios.get(`/costing-request/attachments/${crNumber}/${stepId}`, {
      params: {
        reference_type: referenceType,
      },
    });
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const existingAttachments = currentValues.existingAttachments || [];
    const filesLength = existingAttachments.length + attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const resetAttachmentsModal = () => {
    setAttachmentModal(initialAttachmentState);
  };

  const handleAttachmentSubmit = async () => {
    try {
      setAttachmentsLoading(true);
      const { attachments, plantId } = attachmentModal;

      const formData = new FormData();
      formData.append('reference_type', stepsKeys[activePage]);

      for (const attachment of attachmentModal.existingAttachments) {
        formData.append('attachmentId[]', attachment.id);
      }

      const chunks = attachmentChunks(attachments.filter((file) => file.size));
      if (Object.keys(chunks).length > 0) {
        for (const chunk of Object.values(chunks)) {
          for (const file of chunk) {
            formData.append('attachments', file);
          }
          await uploadAttachment(formData, plantId);
        }
      } else {
        await uploadAttachment(formData, plantId);
      }

      setAttachmentModal({ ...attachmentModal, open: false });
      fetchAttachments(plantId);
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      setAttachmentsLoading(false);
    }
  };

  const uploadAttachment = async (formData, plantId) => {
    try {
      const res = await axios.post(`/costing-request/attachments/${crNumber}/${plantId}`, formData);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const downloadAttachment = async (name) => {
    try {
      const { data } = await axios.get(`/costing-request/attachments/download/${crNumber}`, {
        params: {
          name,
        },
      });
      const base64String = data.data.downloaded;
      const ext = name.split('.');
      saveAs(`${EXTENSIONS[ext[ext.length - 1].toLowerCase()]}${base64String}`, name);
    } catch (e) {
      throw e;
    }
  };

  const deleteAttachment = async (deletedAttachment, plantId) => {
    try {
      const formData = new FormData();
      formData.append('reference_type', stepsKeys[activePage]);
      for (const attachment of attachmentModal.existingAttachments) {
        if (deletedAttachment.id !== attachment.id)
          formData.append('attachmentId[]', attachment.id);
      }
      await uploadAttachment(formData, plantId);
      fetchAttachments(plantId);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Context.Provider
      value={{
        fetchAttachments,
        attachmentModal,
        setAttachmentModal,
        downloadAttachment,
        deleteAttachment,
        resetAttachmentsModal,
        attachmentLoading,
        getAttachmentsRequest,
        setDisableUploads,
      }}
    >
      {children}
      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleAttachmentSubmit}
          onRemove={handleFileRemove}
          attachmentLoading={attachmentLoading}
          readOnly={disableUploads}
        />
      )}
    </Context.Provider>
  );
};

export const useCrAttachments = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useCrStatusCancelled must be used within a CostingRequestsProvider');
  }
  return context;
};

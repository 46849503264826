import { Container, Grid, Group, Modal, Text } from '@mantine/core';

export const NotificationModal = ({
  notificationModalOpen,
  setNotificationModalOpen,
  title,
  notificationText,
}) => {
  return (
    <Modal
      opened={notificationModalOpen}
      onClose={() => setNotificationModalOpen(false)}
      title={title}
      centered
    >
      <Container>
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='confirm-modal-group'>
              <Text size='sm'>{notificationText}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={4}>
            <Group noWrap className=''>
            </Group>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};

import moment from 'moment';
import { useEffect, useMemo, useState, useContext } from 'react';
import { Button } from '@mantine/core';
import MantineTable from '../../../../../common/table/mantine';
import { axios } from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Status } from '../../../styles';
import { IconRefresh,IconReload  } from '@tabler/icons-react';
import { showAlert } from '../../../../../utils/alerts';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
export const UpdateVLC = ({ setLogsModal, activeTab, activeSubTab }) => {
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const { getIntCrUserRoleMappings, userRoleMappings } = useContext(CostingRequestsContext);

  useEffect(() => {
    const fetchMappings = async () => {
      await getIntCrUserRoleMappings();
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (activeTab === 'tpSetup' && activeSubTab === 'updateVlc' && userRoleMappings.length > 0)
      fetch();
  }, [activeTab, activeSubTab, userRoleMappings]);
  const getAllowedCountry = () => {
    try {
      let countries = userRoleMappings
        .filter((role) => role.country && role.is_active && role.int_role?.name === 'GCC FP&A')
        .map((role) => role.country);

      countries = [...new Set(countries)];
      return countries;
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/tp-vlc-data/${crNumber}`);
      const data = response.data.data.map((item) => {
        return {
          sku_number: item?.int_cr_sku_selector?.sku,
          sku_description: item?.int_cr_sku_selector?.sku_description,
          producing_plant: item?.int_cr_producing_plant?.producing_plant,
          producing_country: item?.IntCrTpVlcData?.[0]?.producing_country,
          logs: item.IntCrStepStatusLogs,
          fpna_gcc_sme: item?.costing_ticket_assigned?.name || 'N/A',
          spoc: item?.step_assigned?.name || 'N/A',
          step_id: item.step_id,
          sku_id: item.sku_id,
          status: item?.status,
          producing_plant_id: item.producing_plant_id,
          zz50: item?.IntCrTpVlcData?.[0]?.zz50,
          zz60: item?.IntCrTpVlcData?.[0]?.zz60,
          vplc: item?.IntCrTpVlcData?.[0]?.vplc,
          currency: item?.IntCrTpVlcData?.[0]?.currency_key,
          id: item?.id,
        };
      });
      setSkus(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  const handleRefresh = async (sku_number, producing_country,zz50,zz60,vplc) => {
    setLoading(true);
    const payload = {
      sku: sku_number,
      producing_country: producing_country,
      zz50:zz50,
      zz60:zz60,
      vplc:vplc
    };
    try {
      const response = await axios.post(
        `/costing-request/retry-tp-vlc-update/${crNumber}`,
        payload,
      );
      if (response.status === 200) {
        showAlert('', 'success');
      } else {
        throw new Error(response.data.message || 'Failed to refresh costing request');
      }
      await fetch();
    } catch (error) {
      showAlert('', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchLogs = async (id) => {
    try {
      const url = `/costing-request/vlc-logs/${crNumber}?id=${encodeURIComponent(id)}`;
      const response = await axios.get(url);
      if (response.status !== 200) {
        showAlert({ message: 'Failed to fetch logs' }, 'error');
      }
      return response.data.data;
    } catch (error) {
      console.error('Error fetching logs:', error);
      throw error;
    }
  };

  const checkAccess = (status, country) => {
    const allowedCountries = getAllowedCountry();
    const res =
      allowedCountries.includes(country) &&
      (String(status).toLowerCase() === 'in progress' ||
        String(status).toLowerCase() === 'pending');
    return res;
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: 'action',
        header: 'Action',
        Cell: ({ row }) => {
          const producingCountry = row.original?.producing_country ?? '';
          const sku_number = row.original?.sku_number ?? '';
          const country = row.original?.producing_country;
          const status = row.original?.status;
          const zz50= row.original.zz50;
          const zz60= row.original.zz60;
          const vplc= row.original.vplc
          const isDisabled = checkAccess(status, country);
          return (
            <Button
              variant='subtle'
              color='dark'
              size='md'
              onClick={() => handleRefresh(sku_number, producingCountry,zz50,zz60,vplc)}
              // disabled={!isDisabled}
            >
              <IconReload  color='black' variant='filled' size={16} />
            </Button>
          );
        },
      },
      {
        accessorKey: 'sku_number',
        header: 'SKU No',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Desc',
      },
      {
        accessorKey: 'producing_country',
        header: 'Producing Country',
      },
      {
        accessorKey: 'currency',
        header: 'Curr',
      },
      {
        accessorKey: 'zz50',
        header: 'ZZ50[DstCH-02]',
      },
      {
        accessorKey: 'zz60',
        header: 'ZZ60[DstCH-02]',
      },
      {
        accessorKey: 'vplc',
        header: 'VPLC',
      },
      {
        id: 'logs',
        header: 'Logs',
        Cell: ({ row }) => {
          const sku = row.original?.id;
          const plant = row.original?.producing_plant;
          return (
            <Button
              color='dark'
              onClick={async () => {
                try {
                  const logs = await fetchLogs(sku);
                  setLogsModal({
                    open: true,
                    logs: logs,
                    type: 'Update VLC Check',
                  });
                } catch (error) {
                  console.error('Error fetching logs:', error);
                }
              }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }) => (row.original?.status ? <Status status={row.original?.status} /> : null),
      },
      {
        accessorKey: 'fpna_gcc_sme',
        header: 'FP&A GCC SME',
      },
    ],
    [skus, userRoleMappings],
  );
  return (
    <>
      <MantineTable
        columns={columns}
        initialData={skus}
        unique='sku'
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        csvFilename={`update_vlc_tp_${moment().format('YYYY-MM-DD')}`}
        pageSize={10}
      />
    </>
  );
};

import moment from 'moment';

export const transformData = (data) => {
  const newData = data.map((ele) => {
    ele.version = '';
    ele.id = ele?.IntCrTpFinalizations?.[0]?.id;
    ele.control_number = ele?.IntCrTpFinalizations?.[0]?.tp_code;
    ele.manufacturing_entity_name = '';
    ele.manufacturing_service = '';
    ele.manufacturing_plant = '';
    ele.mu_percent = '';
    ele.updated_by = ele?.IntCrTpFinalizations?.[0]?.LogisticsFpNa_UpdatedBy?.name;
    ele.producing_zone = ele?.IntCrTpFinalizations?.[0]?.producing_zone;
    ele.producing_country = ele?.IntCrTpFinalizations?.[0]?.producing_country;
    ele.erp_sku_number = ele?.IntCrTpFinalizations?.[0]?.sku;
    ele.sku_name = ele?.IntCrTpFinalizations?.[0]?.sku_description;
    ele.sales_org = ele?.IntCrTpFinalizations?.[0]?.sales_org;
    ele.buying_country = ele?.IntCrTpFinalizations?.[0]?.buying_country;
    ele.buying_zone = ele?.IntCrTpFinalizations?.[0]?.buying_zone;
    ele.vplc = ele?.IntCrTpFinalizations?.[0]?.vplc_calc;
    ele.std_logistics_costs = ele?.IntCrTpFinalizations?.[0]?.stg_log;
    ele.leffe_royalties = ele?.IntCrTpFinalizations?.[0]?.leffe_royalities;
    ele.submitted_on = moment(ele?.IntCrTpFinalizations?.[0]?.updatedAt).isValid()
      ? moment(ele?.IntCrTpFinalizations?.[0]?.updatedAt).utc().startOf('day').format('DD-MM-YYYY')
      : null;
    ele.completed_on = moment(ele?.IntCrTpFinalizations?.[0]?.finalzedAt).isValid()
      ? moment(ele?.IntCrTpFinalizations?.[0]?.finalzedAt).utc().startOf('day').format('DD-MM-YYYY')
      : null;
    ele.brand = ele?.IntCrTpFinalizations?.[0]?.sku_brand;
    ele.fob = ele?.IntCrTpFinalizations?.[0]?.fob_calc;
    ele.vlc = ele?.IntCrTpFinalizations?.[0]?.vlc;
    ele.fixed_costs = ele?.IntCrTpFinalizations?.[0]?.fixed_cost;
    ele.dep = ele?.IntCrTpFinalizations?.[0]?.dep;
    ele.royalties = ele?.IntCrTpFinalizations?.[0]?.royalty;
    ele.std_mu = ele?.IntCrTpFinalizations?.[0]?.std_mu;
    ele.other_intangible_compensation = ele?.IntCrTpFinalizations?.[0]?.intangile_compensation;
    ele.incoterm = ele?.IntCrTpFinalizations?.[0]?.incoterm;
    ele.curr = ele?.IntCrTpFinalizations?.[0]?.currency_key;
    ele.total_tp = ele?.IntCrTpFinalizations?.[0]?.total_tp
      ? parseFloat(ele?.IntCrTpFinalizations?.[0]?.total_tp).toFixed(2)
      : '0.00';
    ele.pack_type = ele?.IntCrTpFinalizations?.[0]?.sku_pack_type;
    ele.producing_country = ele?.IntCrTpFinalizations?.[0]?.producing_country;
    ele.vplc = ele?.IntCrTpFinalizations?.[0]?.vplc_calc;
    ele.fpna_logistics_sme = ele?.LogisticsFpNa_sme?.name;
    ele.fpna_gcc_sme = ele?.FPnA_sme?.name;
    ele.indicative_database = String(ele?.IntCrTpFinalizations?.[0]?.indicative_database)
      .toLowerCase()
      .split(',');
    ele.vic = ele?.IntCrTpFinalizations?.[0]?.vic_calc;
    ele.status =
      String(ele?.status).toLowerCase() === 'in progress'
        ? ele?.IntCrTpFinalizations?.[0]?.status
        : ele.status;
    ele.interco_markup_in_perf = ele?.IntCrTpFinalizations?.[0]?.interco_markup_in_perf
      ? parseFloat(ele?.IntCrTpFinalizations?.[0]?.interco_markup_in_perf).toFixed(2)
      : '0.00';
    ele.interco_markup_out_perf = ele?.IntCrTpFinalizations?.[0]?.interco_markup_out_perf
      ? parseFloat(ele?.IntCrTpFinalizations?.[0]?.interco_markup_out_perf).toFixed(2)
      : '0.00';
    // add all the deatils then check access intangile_compensation
    //   ele.accessAllowed = checkAccess('', '');
    return ele;
  });
  return newData;
};

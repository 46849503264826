import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Checkbox, NumberInput, Button, Tabs, Switch, Space, TextInput } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import MantineTable from '../../../../common/table/mantine';
import { useParams } from 'react-router-dom';
import { IconRefresh } from '@tabler/icons-react';
import { CostingRequestsContext, useCrStatusCancelled } from '../../../../contexts/CostingRequest';
import { createStyles } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import styled from '@emotion/styled';
import { showAlert } from '../../../../utils/alerts';
import { ProjectOverview } from '../projectOverview';
import { checkDiff, checkIfFlagged, formatFloat, transformData } from './helper';
import { AppLoader } from '../../../../components/common/loader';
import { NotificationModal } from '../../../../components/modal/NotificationModal';
import { RefBadge } from './refBadge';

const FooterContainer = styled.div`
  float: right;
  button {
    width: 200px;
  }
`;
const notificationText = `Please ensure that you are logged out of the "change T codes in SAP", 
 otherwise this will fail. `;
const notificationTitle = '';
export const NormsCheck = ({ activeTab }) => {
  const isCancelled = useCrStatusCancelled();
  const { crNumber } = useParams();
  const { info, crUserPermissions } = useContext(CostingRequestsContext);
  const [loading, setLoading] = useState(false);
  const [baseSku, setBaseSku] = useState([]);
  const [switchState, setSwitchState] = useState(false);
  const [activePlant, setActivePlant] = useState();
  const { producing_plant_data = [], sku_data = [] } = info || {};
  const [normStatus, setNormStatus] = useState();
  const [btnDisable, setBtnDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
 
  useEffect(() => {
    if (activeTab === 'normAdjustment') {
      fetch(true);
      setActivePlant(producing_plant_data[0]?.producing_plant);
    }
  }, [activeTab]);
  useEffect(() => {
    if (activeTab === 'normAdjustment') {
      handleSubmitStatus(normStatus, activePlant);
    }
  }, []);

  const fetch = async (refresh = false, plant = activePlant) => {
    if (!plant) plant = producing_plant_data[0]?.producing_plant;
    if (refresh) {
      try {
        await axios.post(`/costing-request/step/norms/refresh/${crNumber}/?plant=${plant}`, {});
      } catch (e) {}
    }
    try {
      setLoading(true);

      const response = await axios.get(`/costing-request/step/norms/${crNumber}`);
      const apiData = response.data?.data;
      setNormStatus(response?.data?.norm_status);
      handleSubmitStatus(response?.data?.norm_status, plant);
      const updatedData = transformData(apiData);
      setBaseSku(updatedData);
    } catch (err) {
      console.log('err : normsCheck.js', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitStatus = (step, plant = activePlant) => {
    if (Array.isArray(step) && step.length > 0) {
      const activePlantId = producing_plant_data.filter(
        (ele) => ele.producing_plant === String(plant),
      )?.[0]?.id;
      const stepsData = step.filter((ele) => ele.producing_plant_id === activePlantId);
      if (Array.isArray(stepsData)) {
        (stepsData?.[0]?.status === 'In Progress' || stepsData?.[0]?.is_submission_allowed) &&
        crUserPermissions[String(plant)]?.['standard/transfer_pricing'].is_accessible
          ? setBtnDisable(false)
          : setBtnDisable(true);
      }
      return;
    }
  };

  const queueApiCalls = async (skus, batchSize) => {
    try {
      let queue = [...skus];
      let results = [];
      let error = [];

      const executeBatch = async () => {
        const currentBatch = queue.splice(0, batchSize);
        if (currentBatch.length > 0) {
          const dataToSave = currentBatch.filter(
            (item) => item.itemModified && item.producingPlant.producing_plant === activePlant,
          );
          const responses = await Promise.allSettled(
            dataToSave.map(async (prevData) => {
              try {
                return await handleSaveChange(prevData);
              } catch (err) {
                error.push(prevData.component_description);
                throw err;
              }
            }),
          );

          results.push(...responses);

          // Continue processing the next batch
          await executeBatch();
        }
      };

      await executeBatch();
      return { results, error };
    } catch (error) {
      throw error;
    }
  };

  const handleAutoAdjustment = () => {
    setBaseSku((prevData) =>
      prevData.map((ele) => {
        if (
          ele.reference_bom?.[0]?.quantity &&
          formatFloat(ele.quantity) !== formatFloat(ele.reference_bom?.[0]?.quantity)
        ) {
          ele.quantity = ele.reference_bom?.[0]?.quantity;
          ele.itemModified = true;
        }
        if (
          ele.reference_bom?.[0]?.activity_type &&
          ele.activity_type !== ele.reference_bom?.[0].activity_type
        ) {
          ele.activity_type = ele.reference_bom?.[0].activity_type;
          ele.itemModified = true;
        }
        if (
          ele.reference_bom?.[0]?.charge_quantity &&
          formatFloat(ele.charge_quantity) !== formatFloat(ele.reference_bom?.[0].charge_quantity)
        ) {
          ele.charge_quantity = ele.reference_bom?.[0].charge_quantity;
          ele.itemModified = true;
        }
        if (
          ele.reference_bom?.[0]?.operation_quantity &&
          formatFloat(ele.operation_quantity) !==
            formatFloat(ele.reference_bom?.[0].operation_quantity)
        ) {
          ele.operation_quantity = ele.reference_bom?.[0].operation_quantity;
          ele.itemModified = true;
        }
        ele.flagged = false;
        return ele;
      }),
    );
  };
  const handleSaveChange = (selectedSku) => {
    return new Promise((resolve, reject) => {
      const payload = {
        component_id: selectedSku.component_id,
        quantity: selectedSku.quantity,
        operation_quantity: selectedSku.operation_quantity,
        activity_type: selectedSku.activity_type,
        charge_quantity: selectedSku.charge_quantity,
        comment: '',
      };

      axios
        .post(`/costing-request/step/norms/${crNumber}`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleSubmit = async () => {
    setNotificationModalOpen(true);
    setLoading(true);
    try {
      const { results, error } = await queueApiCalls(baseSku, 1);

      if (error.length > 0) {
        setErrors((o) => {
          return {
            ...o,
            [activePlant]: error,
          };
        });
      }

      const payload = {
        plant: activePlant,
      };
      axios
        .post(`/costing-request/step/norms/submit/${crNumber}`, payload)
        .then((res) => {
          if (res?.data?.success && error.length === 0) {
            showAlert({ message: 'Successfully Submitted' }, 'success');
          } else {
            showAlert({ message: `Failed to save ${error.join(', ')}` }, 'error');
          }
        })
        .catch((error) => {
          showAlert({ message: error?.response?.data?.error }, 'error');
        })
        .finally(() => {
          fetch(true);
        });
    } catch (e) {
      showAlert(e, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = useCallback((id, field, value, data) => {
    setBaseSku((prevData) =>
      prevData.map((ele) => {
        if (ele?.component_id === id) {
          ele[field] = value;
          ele['flagged'] = checkIfFlagged(ele, ele?.reference_bom?.[0]);
          ele.itemModified = true;
        }
        return ele;
      }),
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'component_description',
        header: 'Norm Name',
        index: 0,
      },
      {
        accessorKey: 'component',
        header: 'Norm Resource',
      },
      {
        id: 'duration_excel',
        accessorFn: (row) => row,
        header: 'Duration',
        Cell: ({ row }) => {
          const { diff, variance } = checkDiff(
            row?.original?.quantity,
            row?.original?.reference_bom?.[0]?.quantity,
          );
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row?.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Main:
                </span>
                <NumberInput
                  disabled={btnDisable || isCancelled}
                  precision={3}
                  min={0}
                  step={0.01}
                  value={parseFloat(row.original?.quantity || '') || 0}
                  onChange={(val) =>
                    handleInputChange(
                      row.original?.component_id ?? '',
                      'quantity',
                      val,
                      row.original,
                    )
                  }
                  styles={(theme) => ({
                    input: {
                      borderColor: diff ? 'red' : theme.colors.gray[5],
                      borderWidth: '1px',
                      margin: '0px',
                      width: '100px',
                    },
                  })}
                />
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  {'\xa0\xa0'}
                  <TextInput
                    value={parseFloat(row.original?.reference_bom?.[0]?.quantity) || 0}
                    styles={(theme) => ({
                      input: {
                        width: '100px',
                      },
                    })}
                    readOnly
                    disabled
                  />
                </div>
              )}
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginTop: '3px' }}>
                    Var :{'\xa0\xa0'}{' '}
                    <span style={parseInt(variance) === 0 ? {} : { color: 'red' }}>
                      {Math.abs(variance)}
                    </span>
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'unit_excel',
        accessorFn: (row) => row,
        header: 'Unit',
        size: 40,
        Cell: ({ row }) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id || 'key-1'} style={{ marginRight: '8px' }}>
                  Main:{'\xa0'}
                  {row.original?.component_unit}
                </span>
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                    Ref: {'\xa0\xa0'}
                    {row.original?.reference_bom?.[0]?.component_unit}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'activity_type_excel',
        accessorFn: (row) => row,
        header: 'Activity Type',
        grow: false,
        size: 40,
        Cell: ({ row }) => {
          const isRefActivityTypeThere =
            row.original?.activity_type !== '' &&
            row.original?.reference_bom?.[0]?.activity_type
              ? true
              : false;
          const isDiff = isRefActivityTypeThere
            ? (row.original?.activity_type || '').trim() !==
              (row.original?.reference_bom?.[0]?.activity_type || '').trim()
            : false;
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                  <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main:
                </span>
                <TextInput
                  disabled={btnDisable || isCancelled || row.original?.activity_type !== ''}
                  value={row.original?.activity_type || ''}
                  onChange={(event) => {
                    handleInputChange(
                      row.original?.component_id ?? '',
                      'activity_type',
                      event.target.value.toLocaleUpperCase(),
                      row.original,
                    );
                  }}
                  styles={(theme) => ({
                    input: {
                      borderColor: isDiff ? 'red' : theme.colors.gray[5],
                      borderWidth: '1px',
                      margin: '0px',
                      width: '100px',
                    },
                  })}
                />
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span key={row.original.primary?.sku_id} style={{ marginRight: '8px' }}>
                    Ref:
                  </span>
                  {'\xa0\xa0'}
                  <TextInput
                    readOnly
                    disabled
                    value={row.original?.reference_bom?.[0]?.activity_type || ''}
                    styles={(theme) => ({
                      input: {
                        margin: '0px',
                        width: '100px',
                      },
                    })}
                  />
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'charge_qty_excel',
        accessorFn: (row) => row,
        header: 'Charge Qty',
        Cell: ({ row }) => {
          const { diff, variance } = checkDiff(
            row?.original?.charge_quantity,
            row?.original?.reference_bom?.[0]?.charge_quantity,
          );
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original.requested?.sku_id} style={{ marginRight: '8px' }}>
                  Main:
                </span>
                <NumberInput
                  disabled={btnDisable || isCancelled}
                  precision={0}
                  min={0}
                  step={1}
                  max={1000000}
                  value={parseInt(row.original?.charge_quantity || '') || 0}
                  onChange={(val) =>
                    handleInputChange(
                      row.original?.component_id ?? '',
                      'charge_quantity',
                      val,
                      row.original,
                    )
                  }
                  styles={(theme) => ({
                    input: {
                      borderColor: diff ? 'red' : theme.colors.gray[5],
                      borderWidth: '1px',
                      margin: '0px',
                    },
                  })}
                />
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  {'\xa0\xa0'}
                  <NumberInput
                    readOnly
                    min={0}
                    value={parseInt(row.original?.reference_bom?.[0]?.charge_quantity) || 0}
                    disabled
                  />
                </div>
              )}
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginTop: '3px' }}>
                    Var :{'\xa0\xa0'}{' '}
                    <span style={parseInt(variance) === 0 ? {} : { color: 'red' }}>
                      {Math.abs(variance)}
                    </span>
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'charge_qty_unit_excel',
        accessorFn: (row) => row,
        header: 'C Unit',
        grow: false,
        size: 30,
        Cell: ({ row }) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main: {row.original?.charge_quantity_unit}
                </span>
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>
                    Ref :{'\xa0'} {row.original?.reference_bom?.[0]?.charge_quantity_unit || ' '}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'operation_qty_excel',
        accessorFn: (row) => row,
        header: 'Operation Qty',
        grow: false,
        size: 30,
        Cell: ({ row }) => {
          const { diff, variance } = checkDiff(
            row?.original?.operation_quantity,
            row?.original?.reference_bom?.[0]?.operation_quantity,
          );
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main:
                </span>
                <NumberInput
                  disabled={btnDisable || isCancelled}
                  precision={0}
                  min={0}
                  step={1}
                  max={1000000}
                  value={parseFloat(row.original?.operation_quantity || '') || 0}
                  onChange={(val) =>
                    handleInputChange(
                      row.original?.component_id ?? '',
                      'operation_quantity',
                      val,
                      row.original,
                    )
                  }
                  styles={(theme) => ({
                    input: {
                      borderColor: diff ? 'red' : theme.colors.gray[5],
                      borderWidth: '1px',
                      margin: '0px',
                    },
                  })}
                />
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>
                    Ref: {row?.original?.reference_bom?.operation_quantity}
                  </span>
                  {'\xa0\xa0'}
                  <NumberInput
                    precision={0}
                    min={0}
                    value={
                      parseInt(row.original?.reference_bom?.[0]?.operation_quantity || '') || 0
                    }
                    readOnly
                    disabled
                  />
                </div>
              )}
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginTop: '3px' }}>
                    Var :{'\xa0\xa0'}{' '}
                    <span style={parseInt(variance) === 0 ? {} : { color: 'red' }}>
                      {Math.abs(variance)}
                    </span>
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'operation_qty_unit_excel',
        accessorFn: (row) => row,
        header: 'OQ Unit',
        grow: false,
        size: 30,
        Cell: ({ row }) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main: {row.original?.operation_quantity_unit}
                </span>
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>
                    Ref :{'\xa0'} {row.original?.reference_bom?.[0]?.operation_quantity_unit || ' '}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'total_value_excel',
        accessorFn: (row) => row,
        header: 'Total Value (per HL)',
        minSize: 30,

        Cell: ({ row }) => {
          const reference = row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation || null;
          const { diff, variance } = checkDiff(
            row.original?.RequestedSkuCostEstimation?.total_value,
            reference?.total_value || 0,
          );
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main:
                </span>
                <NumberInput
                  precision={3}
                  min={0}
                  step={0.01}
                  value={
                    parseFloat(row.original?.RequestedSkuCostEstimation?.total_value || '') || 0
                  }
                  disabled
                  styles={(theme) => ({
                    input: {
                      borderColor: diff ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                      width: '100px',
                    },
                  })}
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  {' \xa0\xa0 '}
                  <NumberInput
                    precision={3}
                    min={0}
                    step={0.01}
                    disabled
                    readOnly
                    value={parseFloat(reference?.total_value || '0') ?? 0}
                    styles={(theme) => ({
                      input: {
                        width: '100px',
                      },
                    })}
                  />
                </div>
              )}
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginTop: '3px' }}>
                    Var :{'\xa0\xa0'}{' '}
                    <span style={parseInt(variance) === 0 ? {} : { color: 'red' }}>
                      {Math.abs(variance)}
                    </span>
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'price_excel',
        accessorFn: (row) => row,
        header: 'Price',
        size: 30,
        Cell: ({ row }) => {
          const reference = row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation || null;
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main: {row.original?.RequestedSkuCostEstimation?.price || ''}
                </span>
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>
                    Ref :{'\xa0\xa0\xa0'}
                    {reference?.Price || reference?.price || 0}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'price_unit_excel',
        accessorFn: (row) => row,
        header: 'Price Unit \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0',
        size: 30,
        Cell: ({ row }) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main: {row.original?.RequestedSkuCostEstimation?.un || ''}
                </span>
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>
                    Ref : {row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.un || ''}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        id: 'price_per_unit_excel',
        accessorFn: (row) => row,
        header: 'Price Per Unit  \xa0\xa0\xa0\xa0\xa0',

        size: 30,
        Cell: ({ row }) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <span key={row.original?.sku_id} style={{ marginRight: '8px' }}>
                  Main: {row.original?.RequestedSkuCostEstimation?.price_unit || ''}
                </span>
              </div>
              {row?.original?.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>
                    Ref :{' '}
                    {row.original?.reference_bom?.[0]?.PrimaryRefSkuCostEstimation?.price_unit ||
                      ''}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
    ],
    [handleInputChange, baseSku, errors],
  );

  const useStyles = createStyles((theme) => ({
    input: {
      outline: '2px solid black',
      outlineOffset: '-1px',
    },
    thumb: {
      color: 'black',
      backgroundColor: 'black',
    },
    track: {
      border: '3px solid black',
      margin: '1px',
    },
  }));
  const { classes } = useStyles();
  const rightSideBtns = [
    {
      button: (
        <Button
          disabled={btnDisable || isCancelled}
          variant='light'
          color='gray.6'
          onClick={() => fetch(true)}
        >
          <IconRefresh color='black' variant='filled' size={16} />
        </Button>
      ),
    },
    {
      button: (
        <Button
          variant='light'
          size='sm'
          color='blue.3'
          disabled={btnDisable || isCancelled}
          styles={(theme) => ({
            label: {
              color: theme.colors.blue[6],
            },
          })}
          onClick={() => handleAutoAdjustment()}
        >
          Auto Adjustment of BOM
        </Button>
      ),
    },
  ];
  const leftButtons = [
    {
      button: (
        <Switch
          label='View Flagged Data'
          onChange={(event) => {
            setSwitchState(event.target.checked);
          }}
          classNames={{ input: classes.input, thumb: classes.thumb, track: classes.track }}
        />
      ),
    },
  ];
  const initialState = {
    columnPinning: {
      left: ['component_description', 'component'],
      right: [],
    },
    sorting: [
      {
        id: 'component_description',
        desc: false,
      },
    ],
  };

  const Footer = (activePlant) => (
    <FooterContainer>
      <Button
        onClick={handleSubmit}
        variant='gradient'
        radius='md'
        size='md'
        fullWidth
        disabled={btnDisable || isCancelled || loading}
      >
        {loading ? <AppLoader size='sm' /> : 'Submit'}
      </Button>
    </FooterContainer>
  );
  return (
    <>
      <ContentPaper footer={<Footer activePlant={activePlant} />}>
        <ProjectOverview />
        <Space h='sm' />
        <Tabs
          keepMounted={false}
          value={activePlant}
          onTabChange={(value) => setActivePlant(value)}
          color='yellow'
        >
          <Tabs.List>
            {producing_plant_data.map(({ producing_plant, id }, index) => (
              <Tabs.Tab value={producing_plant} className='menu-tabs'>
                {producing_plant}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {producing_plant_data.map(({ producing_plant, id }, index) => {
            return (
              <Tabs.Panel value={producing_plant}>
                <MantineTable
                  columns={columns}
                  initialData={baseSku
                    .map((sku) => ({
                      ...sku,
                      error: (errors?.[activePlant] || []).includes(sku.component_description),
                    }))
                    .filter(
                      (item) =>
                        (item?.producingPlant?.producing_plant === producing_plant ||
                          item?.pp_id === producing_plant) &&
                        (!switchState || (switchState && item.flagged)),
                    )}
                  enablePinning
                  initialState={initialState}
                  unique={'indexId'}
                  enableRowSelection={false}
                  loading={loading}
                  showSelectedToggle={false}
                  hideSelectColumn={true}
                  showResetAll={false}
                  enableExport={true}
                  pageSize={10}
                  enableSelectAll={false}
                  rightButtons={rightSideBtns}
                  leftButtons={leftButtons}
                  headerBadge={<RefBadge crNumber={crNumber} />}
                />
              </Tabs.Panel>
            );
          })}
        </Tabs>
      </ContentPaper>
      <NotificationModal
        notificationModalOpen={notificationModalOpen}
        setNotificationModalOpen={setNotificationModalOpen}
        notificationText={notificationText}
        title={notificationTitle}
      />
    </>
  );
};

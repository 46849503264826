import moment from 'moment';
import { useEffect, useMemo, useState, useContext } from 'react';
import { Button, Select } from '@mantine/core';
import MantineTable from '../../../../../common/table/mantine';
import { axios } from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Status } from '../../../styles';
import styled from '@emotion/styled';
import { IconRefresh,IconReload  } from '@tabler/icons-react';
import { showAlert } from '../../../../../utils/alerts';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

export const UpdateSKU = ({ setLogsModal, activeTab, activeSubTab, row }) => {
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const { crUserPermissions } = useContext(CostingRequestsContext);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [submittedSkus, setSubmittedSkus] = useState([]);

  const CenterItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/step/update_sku/${crNumber}`);
      const packTypes = response.data.pack_types;
      const data = response.data.data.map((item) => {
        const status = (item.status || '').toLowerCase();

        const packTypeObj = packTypes.find((pack) => pack.sku_id === item.sku_id);
        const packType = packTypeObj ? packTypeObj.pack_type : '';
        return {
          sku_number: item.int_cr_sku_selector.sku,
          sku_description: item.int_cr_sku_selector.sku_description,
          producing_plant: item.int_cr_producing_plant.producing_plant,
          status:
            status === 'not started'
              ? 'Not Started'
              : status === 'closed'
              ? 'Completed'
              : 'Pending',
          logs: item.IntCrStepStatusLogs,
          assignedTo: item.costing_ticket_assigned?.name || 'N/A',
          spoc: item.step_assigned?.name || 'N/A',
          step_id: item.step_id,
          sku_id: item.sku_id,
          producing_plant_id: item.producing_plant_id,
          packType: item.pack_type || packType,
          isPackTypeSelected: !!(item.pack_type || packType),
          brand: item.int_cr_sku_selector.brand,
          isSubmitted: !!packType,
          submissionSuccess: item.submission_success,
          success: item.submission_success,
        };
      });

      setSkus(data);
    } catch (e) {
      console.error('Error fetching SKUs:', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'tpSetup' && activeSubTab === 'updateSku') {
      fetch();
    }
  }, [activeTab, activeSubTab]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get('/costing-request/step/update_sku/pack_type');
        if (response.data) {
          const data = Array.isArray(response.data) ? response.data : response.data.data;
          const options = data.map((item) => ({
            value: item.pack_type,
            label: item.pack_type,
          }));
          setDropdownOptions(options);
        }
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleRefresh = async (sku_id, pack_type) => {
    if (!pack_type) {
      showAlert(
        {
          message: 'Please select a Pack Type before refreshing',
        },
        'error',
      );
      return;
    }

    try {
      setLoading(true);
      const payload = {
        costing_request_id: crNumber,
        sku_id: sku_id,
        pack_type: pack_type,
      };
      const response = await axios.post(
        `/costing-request/step/update_sku/retry/${crNumber}`,
        payload,
      );

      if (response.status === 200) {
        showAlert(
          {
            message: 'TP Flow submitted successfully in Anaplan',
          },
          'success',
        );
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      showAlert(
        {
          message: 'TP Submission failed in Anaplan. Please check logs',
        },
        'error',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async ({ sku_id, packType }) => {
    try {
      setLoading(true);

      const response = await axios.post(`/costing-request/step/update_sku/${crNumber}`, {
        sku_id: sku_id,
        pack_type: packType,
      });

      if (response.status === 200) {
        if (response.data?.success) {
          showAlert({ message: 'Pack Type updated successfully!' }, 'success');

          setSkus((prevSkus) =>
            prevSkus.map((sku) =>
              sku.sku_id === sku_id
                ? {
                    ...sku,
                    isSubmitted: true,
                    success: true,
                  }
                : sku,
            ),
          );
          await fetch();
        } else {
          showAlert({ message: response.data.message || 'Failed to update Pack Type.' }, 'error');
          setSkus((prevSkus) =>
            prevSkus.map((sku) =>
              sku.sku_id === sku_id
                ? {
                    ...sku,
                    isSubmitted: false,
                    success: false,
                  }
                : sku,
            ),
          );
        }
      }
    } catch (error) {
      showAlert({ message: 'Failed to update Pack Type. Please try again.' }, 'error');
      setSkus((prevSkus) =>
        prevSkus.map((sku) =>
          sku.sku_id === sku_id
            ? {
                ...sku,
                isSubmitted: false,
                success: false,
              }
            : sku,
        ),
      );
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'action',
        header: 'Action',
        Cell: ({ row }) => {
          const plant = row.original?.producing_plant;
          const status = row.original?.status;
          const sku_id = row.original.sku_id;
          const pack_type = row.original.packType;
          const hasRefreshAccess =
            crUserPermissions[String(plant)]?.['standard/transfer_pricing']?.is_accessible;
          const isDisabled =
            status === 'Completed' ||
            !hasRefreshAccess ||
            status === 'Not Started' ||
            !row.original.isSubmitted;

          return (
            <Button
              variant='subtle'
              color='dark'
              size='sm'
              style={{ width: '100%' }}
              onClick={() => handleRefresh(sku_id, pack_type)}
              disabled={isDisabled}
            >
              Retry
            </Button>
          );
        },
      },
      {
        accessorKey: 'sku_number',
        header: 'SKU No',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Desc',
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
      },
      {
        accessorKey: 'packType',
        header: 'Pack Type',
        Cell: ({ row }) => {
          const handlePackTypeChange = (newValue) => {
            setSkus((prevSkus) =>
              prevSkus.map((sku) =>
                sku.sku_id === row.original.sku_id
                  ? {
                      ...sku,
                      packType: newValue,
                      isPackTypeSelected: !!newValue,
                      isSubmitted: false,
                      success: false,
                    }
                  : sku,
              ),
            );
          };
          const isDisabled =
            (row.original.isSubmitted && row.original.success === true) ||
            ['Not Started', 'Completed'].includes(row.original.status);
          return (
            <Select
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              data={dropdownOptions}
              value={row.original.packType}
              onChange={handlePackTypeChange}
              placeholder='Select Pack Type'
              required
              clearable
              searchable
              withinPortal={true}
              disabled={isDisabled}
              zIndex={1000}
              styles={{
                dropdown: {
                  maxHeight: '400px',
                  overflowY: 'auto',
                  position: 'absolute',
                },
              }}
            />
          );
        },
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }) => <Status status={row.original.status} />,
      },
      {
        id: 'logs',
        header: 'Logs',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={() =>
              setLogsModal({
                open: true,
                logs: row.original.logs,
                type: 'Update SKU Check',
              })
            }
          >
            View Logs
          </Button>
        ),
      },
      {
        accessorKey: 'assignedTo',
        header: 'FP&A GCC SME',
      },
      {
        accessorKey: 'action2',
        header: 'Submit',
        Cell: ({ row }) => {
          const isDisabled =
            !row.original.isPackTypeSelected ||
            (row.original.isSubmitted && row.original.success === true) ||
            ['Not Started', 'Completed'].includes(row.original.status);

          return (
            <Button color='dark' disabled={isDisabled} onClick={() => handleSubmit(row.original)}>
              Submit
            </Button>
          );
        },
      },
    ],
    [dropdownOptions, crUserPermissions],
  );

  const initialState = {
    columnPinning: {
      left: ['logs'],
      right: ['action'],
    },
    sorting: [
      {
        id: 'sku_description',
        desc: false,
      },
    ],
  };

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={skus}
        unique='sku'
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        csvFilename={`tp_update_sku_check_${moment().format('YYYY-MM-DD')}`}
        pageSize={10}
      />
    </>
  );
};

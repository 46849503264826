import { useContext, useMemo } from 'react';
import MantineTable from '../../../../common/table/mantine';
import moment from 'moment';
import { Button, NumberInput } from '@mantine/core';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { getFooterInfoToExport } from './helper';
import { useAvic } from './context';

const AvicTable = ({
  data = {},
  loading,
  upload,
  footerData,
  setValues,
  setFooterValues,
  disableUpload,
}) => {
  const { activePage } = useAvic();
  const { info } = useContext(CostingRequestsContext);

  const handleChange = (value, name, plant) => {
    const updatedData = { ...data, [plant]: { ...data[plant], [name]: value } };
    setValues(updatedData);
  };

  const handleFooterChange = (value, name) => {
    const updatedFooterData = { ...footerData, [name]: value };
    setFooterValues(updatedFooterData);
  };

  const calculateFinalAvic = () => {
    const { perf_plug = 0, price_plug = 0, manual_adjustment = 0 } = footerData;
    const wa = calculateWeightedAverage();

    const total =
      parseFloat(wa) + Number(perf_plug) + Number(price_plug) + Number(manual_adjustment);
    return parseFloat(total || 0).toFixed(2);
  };

  const calculateTotalVolume = () => {
    return parseFloat(
      Object.values(data).reduce((sum, plant) => sum + Number(plant.volume), 0),
    ).toFixed(2);
  };

  const calculateTotalAvic = () => {
    return parseFloat(
      Object.values(data).reduce((sum, plant) => sum + plant.lvic * Number(plant.volume), 0),
    ).toFixed(2);
  };

  const calculateWeightedAverage = () => {
    const totalVolume = calculateTotalVolume();
    const totalAvic = calculateTotalAvic();
    const wa = totalAvic / totalVolume;
    return parseFloat(wa || 0).toFixed(2);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sku',
        header: 'SKU No.',
        Cell: () => info?.sku_data?.[0]?.sku || 'N/A',
        footer: info?.sku_data?.[0]?.sku || 'N/A',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Desc.',
        footer: info?.sku_data?.[0]?.sku_description || 'N/A',
      },
      { accessorKey: 'open_plant', header: 'Production Plant' },
      {
        accessorKey: 'volume',
        header: 'Volume (HL)',
        Cell: ({ row }) => (
          <NumberInput
            value={row.original.volume}
            onChange={(value) => handleChange(value, 'volume', row.original.open_plant)}
            precision={2}
            min={0.01}
            step={0.01}
            max={10000000}
            disabled={disableUpload}
          />
        ),
        footer: calculateTotalVolume(),
      },
      { accessorKey: 'lvic', header: 'LVIC/HL' },
      { id: 'W.Avg VIC/HL', header: 'W.Avg VIC/HL', footer: calculateWeightedAverage() },
      {
        id: 'Perf Plug',
        header: 'Perf Plug',
        footer: (
          <NumberInput
            value={footerData?.perf_plug || ''}
            onChange={(value) => handleFooterChange(value, 'perf_plug')}
            precision={4}
            min={-100}
            step={0.01}
            max={100}
            disabled={disableUpload}
          />
        ),
      },
      {
        id: 'Price Plug',
        header: 'Price Plug',
        footer: (
          <NumberInput
            value={footerData?.price_plug || ''}
            onChange={(value) => handleFooterChange(value, 'price_plug')}
            precision={4}
            min={-100}
            step={0.01}
            max={100}
            disabled={disableUpload}
          />
        ),
      },
      {
        id: 'Other Adj',
        header: 'Other Adj',
        footer: (
          <NumberInput
            value={footerData?.manual_adjustment || ''}
            onChange={(value) => handleFooterChange(value, 'manual_adjustment')}
            precision={4}
            min={-100}
            step={0.01}
            max={100}
            disabled={disableUpload}
          />
        ),
      },
      {
        id: 'Final AVIC/HL',
        header: 'Final AVIC/HL',
        footer: calculateFinalAvic(),
      },
      { id: 'Ref SKU', header: 'Ref SKU', footer: footerData.ref_sku },
      { id: 'Ref SKU Desc.', header: 'Ref SKU Desc.', footer: footerData.ref_sku_description },
      { id: 'Ref AVIC/HL', header: 'Ref AVIC/HL', footer: footerData.ref_sku_avic },
      { id: 'Curr', header: 'Curr', footer: footerData.ref_avic_cur },
      {
        id: activePage === 'avicSubmission' ? 'FP&A GCC SME' : 'FP&A Brewery Head',
        header: activePage === 'avicSubmission' ? 'FP&A GCC SME' : 'FP&A Brewery Head',
        footer: footerData.updated_by,
      },
      {
        id: 'Submitted On',
        header: 'Submitted On',
        footer:
          footerData.submitted_on && moment(footerData?.submitted_on).isValid()
            ? moment(footerData.submitted_on).format('YYYY-MM-DD')
            : 'N/A',
      },
    ],
    [data, footerData, disableUpload],
  );

  const rightButtons = [
    {
      button: (
        <Button variant='gradient' color='dark' onClick={upload} disabled={disableUpload}>
          Upload Attachment
        </Button>
      ),
    },
  ];

  return (
    <MantineTable
      columns={columns}
      initialData={Object.values(data)}
      enableRowSelection={false}
      loading={loading}
      showSelectedToggle={false}
      hideSelectColumn
      showResetAll={false}
      pageSize={10}
      enableColumnFilter={false}
      enableFilters={false}
      enableExport
      columnFilterDisplayMode={false}
      rightButtons={rightButtons}
      csvFilename={`avic_${moment().format('YYYY-MM-DD')}`}
      className='avic-table'
      footerData={getFooterInfoToExport(Object.values(data), footerData, info, activePage)}
    />
  );
};

export default AvicTable;

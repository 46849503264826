import moment from 'moment';
import React from 'react';
import { useEffect, useMemo, useState,useContext } from 'react';
import { Button ,Text, Select, Tooltip  } from '@mantine/core';
import MantineTable from '../../../../../common/table/mantine';
import { axios } from '../../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Status } from '../../../styles';
import styled from '@emotion/styled';
import { IconRefresh, IconTrash, IconCheck,IconReload  } from '@tabler/icons-react';
import { showAlert } from '../../../../../utils/alerts';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
import { Dropdown } from 'react-bootstrap';

export const TpErpSetup = ({ setLogsModal, activeTab,activeSubTab }) => {
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);
  const { crUserPermissions } = useContext(CostingRequestsContext);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [retryEnabled, setRetryEnabled] = useState(false);
  const CenterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

  useEffect(() => {
    if (activeTab === 'tpSetup' && activeSubTab === 'tpErpSetup') {
      fetch();
    }
  }, [activeTab,activeSubTab]);

  // Fetch dropdown options
  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get('costing-request/all-conditions');
      if (response.data && Array.isArray(response.data)) {
        const processedData = response.data.map(item => ({
          ...item,
          condition_type: item.condition_type ? item.condition_type.split(',').map(type => type.trim()) : [],
          key_combination: item.key_combination,
        }));
        setDropdownOptions(processedData);
      }
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/tp-erp-setup/${crNumber}`);
      const data = response.data.data.flatMap((item) => {
        if (item.IntCrTpErps.length > 0){

          return item.IntCrTpErps.map((erp) =>  {
            const status = (erp.status || '').toLowerCase();
            return{
            // ...erp,
            sku_number: item.int_cr_sku_selector.sku,
            sku_description: item.int_cr_sku_selector.sku_description,
            producing_plant: item.int_cr_producing_plant.producing_plant,
            status: status === 'not started' ? 'Not Started' : status === 'closed' ? 'Completed' : 'Pending',
            logs: item.IntCrStepStatusLogs,
            assignedTo: item.costing_ticket_assigned?.name || 'N/A',
            spoc: item.step_assigned?.name || 'N/A',
            step_id: item.step_id,
            id:erp.id,
            sku_id: item.sku_id,
            producing_plant_id: item.producing_plant_id,
            sales_org: erp.sales_org,
            distribution_channel: erp.distribution_channel,
            condition_record_id: erp.id,
            selectedKeyCombination: erp.key_combination || null,
            selectedConditionType: erp.condition_type || null,
            // selectedKeyCombination:  null,
            // selectedConditionType:  null,
            cost_per_hl: erp.cost_per_hl,
            cost_per_100hl: erp.cost_per_100hl,
            currency_key: erp.currency_key,
            isEditable: !erp.key_combination,
            isSaved: !!erp.key_combination,
            // isEditable: true,
            // isSaved: false,
            valid_from: moment(erp.valid_from).isValid()
              ? moment(erp.valid_from).format('DD-MM-YYYY')
              : null,
            valid_to: moment(erp.valid_to).isValid()
              ? moment(erp.valid_to).format('DD-MM-YYYY')
              : null,
            completed_on: moment(erp?.completed_on).isValid()
              ? moment(erp?.completed_on).format('DD-MM-YYYY')
              : 'N/A',
            per: erp.per,
            unit_of_measure: erp.unit_of_measure,
            retryEnabled: false,
            }
          });
        }else {
          const status = (item.status || '').toLowerCase();
          return{

            sku_number: item.int_cr_sku_selector.sku,
              sku_description: item.int_cr_sku_selector.sku_description,
              producing_plant: item.int_cr_producing_plant.producing_plant,
              status: status === 'not started' ? 'Not Started' : status === 'closed' ? 'Completed' : 'Pending',
              logs: item.IntCrStepStatusLogs,
              assignedTo: item.costing_ticket_assigned?.name || 'N/A',
              spoc: item.step_assigned?.name || 'N/A',
              step_id: item.step_id,
          }
        }
      });
      setSkus(data);
    } catch (e) {
      console.error('Error fetching data:', e);
    } finally {
      setLoading(false);
    }
  };
const handleRefresh = async (rowData,valid_from,valid_to) => {
    try {
    if (!rowData.selectedKeyCombination || !rowData.selectedConditionType) {
        showAlert({ message: 'Please select both Key Combination and Condition Type before refreshing' }, 'error');
        return;
      }
      setLoading(true);
      const payload = {
        costing_request_id: crNumber,
        distribution_channel: rowData.distribution_channel,
        condition_type: rowData.selectedConditionType,
        condition_record_id: rowData.condition_record_id,
        key_combination: rowData.selectedKeyCombination,
        flag: 'Retry',
        // valid_from: validFrom,   // Add Valid From
        // valid_to: validTo,
      };
  
      const response = await axios.post(`/costing-request/tp-erp-retry/${crNumber}`, payload);
  
      if (response.status === 200 && response.data.status === 'success') {
        showAlert(
          {
            message: `TP Setup successfully refreshed for Sales Org ${rowData.sales_org}`,
          },
          'success'
        );
  
        setSkus((prevSkus) =>
          prevSkus.map((sku) =>
            sku.id === rowData.id
              ? {
                  ...sku,
                  status: 'Pending',
                  retryEnabled: false, // Disable retry
                  // valid_from: validFrom,   
                  // valid_to: validTo,
                }
              : sku
          )
        );
      } else {
        throw new Error(response.data.message || 'Refresh failed. Please check the logs.');
      }
    } catch (error) {
      showAlert(
        {
          message: `TP Setup refresh failed for Sales Org ${rowData.sales_org}. Please check logs.`,
        },
        'error'
      );
      // Re-enable retry button for the failed row
    setSkus((prevSkus) =>
      prevSkus.map((sku) =>
        sku.id === rowData.id
          ? {
              ...sku,
              retryEnabled: true, // Enable retry
            }
          : sku
      )
    );
      console.error('Error during refresh:', error);
    } finally {
      setLoading(false);
    }
  };
  

const fetchLogs = async () => {
  try {
    const url = `/costing-request/tp-erp-setup-logs/${crNumber}`;
    const response = await axios.get(url);
    if (response.status !== 200) {
      throw new Error('Failed to fetch logs');
    }
    return response.data.data;
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
}

  const handleSave = async (rowData) => {
    try {
        // Validation before save
      if (!rowData.selectedKeyCombination || !rowData.selectedConditionType) {
        showAlert({ message: 'Please select both Key Combination and Condition Type' }, 'error');
        return;
      } 
      setLoading(true);
      const payload = {
        costing_request_id: crNumber,
        condition_type: rowData.selectedConditionType,
        key_combination: rowData.selectedKeyCombination,
        id: rowData.id,
        flag: 'Save',
        // valid_from: validFrom,   // Add Valid From
        // valid_to: validTo, 
      };

      const response = await axios.put('/costing-request/update-key-combination/', payload);
      
      if (response.data.success) {
        //setRetryEnabled(false);
        showAlert({message:response.data.message}, 'success' );
        // Update the local state to reflect the changes
        setSkus(prevSkus => 
          prevSkus.map(sku => 
            sku.id === rowData.id 
              ? { ...sku, 
                  key_combination: rowData.selectedKeyCombination,
                  condition_type: rowData.selectedConditionType,
                  isEditable: false,
                  isSaved: true, // Mark as saved
                  retryEnabled: false,
                  // valid_from: validFrom,  // Update the local state with valid_from
                  // valid_to: validTo,
                }
              : sku
          )
        );
        await fetch()
      } else {
        throw new Error(response.data.message || 'Save failed');
      }
    } catch (error) {
      console.error('Save error:', error);
      setRetryEnabled(true);
      showAlert({ message: error.message || 'Save failed' }, 'error');
      
      // Update retryEnabled only for the specific row
      setSkus((prevSkus) =>
        prevSkus.map((sku) =>
          sku.id === rowData.id
            ? {
                ...sku,
                retryEnabled: true, // Enable retry for failed save
              }
            : sku
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'action',
        header: 'Action',
        Cell: ({ row }) => {
          const [isLoading, setIsLoading] = useState(false);
        //   const [retryEnabled, setRetryEnabled] = useState(false);
          const [logs, setLogs] = useState('');
          const [isEditable, setIsEditable] = useState(true);
          const distributionChannel = row.original?.distribution_channel;
          const conditionType = row.original?.condition_type;
          const conditionRecordId = row.original?.condition_record_id;
          const crNumber = row.original?.crNumber;
          const plant =row.original?.producing_plant;
          const status = row.original?.status
          const sales_org = row.original?.sales_org
          const keyCombination = row.original?.key_combination
          const id= row.original?.id
          const hasRefreshAccess =crUserPermissions[String(plant)]?.['standard/transfer_pricing']
                                  ?.is_accessible;
          const isDisabled = status === 'Completed' || !hasRefreshAccess;
          const { retryEnabled} = row.original;
          const isReadyToSave = row.original.selectedKeyCombination && 
                               row.original.selectedConditionType && 
                               row.original.isEditable;
          const handleSaveClick = () => handleSave(row.original);
          return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
          {retryEnabled && (
            <Tooltip label="Retry" position="top" withArrow>
            <Button
              variant="subtle"
              color="dark"
              size="sm"
              style={{ width: '100%' }}
              onClick={() => handleRefresh(row.original)}
             disabled={isDisabled || !row.original.selectedKeyCombination || !row.original.selectedConditionType}
            >
              Retry
            </Button>
            </Tooltip>
          )}
  
            {/* Save Button */}
            <Tooltip label="Save changes" position="top" withArrow>
            <Button
                variant="light"
                color="green"
                size="sm"
                style={{ width: '100%' }}
                onClick={handleSaveClick}
                // disabled={isDisabled}
                //disabled={!isEditable || isLoading || isDisabled
                disabled={!isReadyToSave || loading}
                loading={isLoading}
            >
                <IconCheck color="green" variant="filled" size={16} />
            </Button>
            </Tooltip>
        </div>
        );
        },
      },
      {
        accessorKey: 'sku_number',
        header: 'SKU No',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Desc',
      },
      {
        accessorKey: 'sales_org',
        header: 'Sales Org',
      },
      {
        accessorKey: 'distribution_channel',
        header: 'Distribn Channel',
      },
      {
        accessorKey: 'key_combination',
        header: 'Key Combn',
        Cell: ({ row }) => {
          const hasRefreshAccess = crUserPermissions[String(row.original.producing_plant)]?.['standard/transfer_pricing']?.is_accessible;
          const keyCombinations = [...new Set(dropdownOptions.map(item => item.key_combination))];
          
          const handleKeyCombinationChange = (newValue) => {
            const selectedOption = dropdownOptions.find(opt => opt.key_combination === newValue);
            setSkus(prevSkus => 
              prevSkus.map(sku => 
                sku.id === row.original.id
                  ? {
                      ...sku,
                      selectedKeyCombination: newValue,
                      selectedConditionType: null,
                      condition_type: dropdownOptions.find(opt => opt.key_combination === newValue)?.condition_type || [], 
                      isSaved: false         
                    }
                  : sku
              )
            );
          };

          return (
            <Select
              data={keyCombinations.map(comb => ({ value: comb, label: comb }))}
              value={row.original.selectedKeyCombination}
              onChange={handleKeyCombinationChange}
              placeholder="Select Key Combination"
              disabled={!row.original.isEditable || !hasRefreshAccess || row.original.isSaved}
              required
              clearable
              searchable
            />
          );
        }
      },
      {
        accessorKey: 'condition_type',
        header: 'Condn Type',
        Cell: ({ row }) => {
          const hasRefreshAccess = crUserPermissions[String(row.original.producing_plant)]?.['standard/transfer_pricing']?.is_accessible;
          const selectedOption = dropdownOptions.find(opt => 
            opt.key_combination === row.original.selectedKeyCombination
          );
          const conditionTypes = selectedOption?.condition_type || [];

          const handleConditionTypeChange = (newValue) => {
            setSkus(prevSkus => 
              prevSkus.map(sku => 
                sku.id === row.original.id
                  ? { ...sku, selectedConditionType: newValue,
                    isSaved: false 
                  }
                  : sku
              )
            );
          };

          return (
            <Select
              data={conditionTypes.map(type => ({ value: type, label: type }))}
              value={row.original.selectedConditionType}
              onChange={handleConditionTypeChange}
              placeholder="Select Condition Type"
              disabled={!row.original.isEditable || !row.original.selectedKeyCombination || !hasRefreshAccess || row.original.isSaved}
              required
              clearable
              searchable
            />
          );
        }
      },
    {
        accessorKey: 'cost_per_hl',
        header: 'Cost/HL',
    },
    {
        accessorKey: 'cost_per_100hl',
        header: 'Cost/100 HL',
    },
    {
        accessorKey: 'currency_key',
        header: 'Curr',
    },
    {
        accessorKey: 'per',
        header: 'Unit',
    },
    {
        accessorKey: 'unit_of_measure',
        header: 'UoM',
    },
    {
        accessorKey: 'valid_from',
        header: 'Valid From',
    },
    {
        accessorKey: 'valid_to',
        header: 'Valid To',
    },
    {
      id: 'status',
      header: 'Status',
      Cell: ({ row }) => <Status status={row.original.status} />,
    },
    {
      id: 'logs',
      header: 'Logs',
      Cell: () => {
        return (
          <Button
            color="dark"
            onClick={async () => {
              try {
                const logs = await fetchLogs();
                setLogsModal({
                  open: true,
                  logs: logs,
                  type: 'TP ERP Setup Check',
                });
              } catch (error) {
                console.error('Error fetching logs:', error);
              }
            }}
          >
            View Logs
          </Button>
        );
      },
    },
    {
      accessorKey: 'assignedTo',
      header: 'FP&A GCC SME',
    },
    {
        accessorKey: 'completed_on',
        header: 'Completed On',
    },
    ],
    [dropdownOptions, loading,crUserPermissions]
  );

  return (
    <>
    <MantineTable
      columns={columns}
      initialData={skus}
      unique="id"
      enableRowSelection={false}
      loading={loading}
      showSelectedToggle={false}
      hideSelectColumn={true}
      showResetAll={false}
      enableExport={true}
      csvFilename={`tp_erp_setup_check_${moment().format('YYYY-MM-DD')}`}
      pageSize={10}
    />
    </>
  );
};
import { useMemo, useState } from 'react';
import MantineTable from '../../../../../common/table/mantine';
import { Button, NumberInput } from '@mantine/core';
import { Refresh, IconReload } from '@tabler/icons-react';
import { Status } from '../../../styles';

const VLCTable = ({
  handleRefresh,
  data,
  handleCostChange,
  loading,
  setLogsModal,
  logsHeader,
  csvFilename,
  submitCostChange,
  setData,
  checkActive,
  checkActiveFpna,
}) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'logs',
        header: 'Action',
        Cell: ({ row }) => {
          const status = row.original?.status;
          const country = row.original?.producing_country;
          const value = row?.original?.costPerHL;
          const access = checkActiveFpna(status, country, value);

          return (
            <Button
              disabled={!access}
              variant='light'
              color='gray.0'
              size='md'
              onClick={() => handleRefresh(row.original?.id, row?.original?.sales_org)}
            >
              Retry
            </Button>
          );
        },
      },
      {
        id: 'sku',
        header: 'SKU No',
        Cell: ({ row }) => {
          return <div>{row.original?.sku}</div>;
        },
      },
      {
        id: 'sku_desc',
        header: 'SKU Description',
        Cell: ({ row }) => {
          return <div>{row.original?.sku_desc}</div>;
        },
      },
      {
        accessorKey: 'sales_org',
        header: 'Sales Org',
      },
      {
        accessorKey: 'distribution_channel',
        header: 'Distribution Channel',
      },
      {
        accessorKey: 'condition_type',
        header: 'Condn Type',
      },
      {
        accessorKey: 'key_combination',
        header: 'Key Combn',
      },
      {
        accessorKey: 'costPerHL',
        header: 'Cost/HL',
        Cell: ({ row }) => {
          const status = row.original?.status;
          const country = row.original?.producing_country;
          const access = checkActive(status, country, 'logistics');
          return (
            <NumberInput
              disabled={!access}
              min={0}
              max={1000000}
              precision={5}
              value={parseFloat(row.original?.costPerHL || 0) || 0}
              onChange={(val) => handleCostChange(val, row?.original?.id)}
            />
          );
        },
      },
      {
        accessorKey: 'costPerHundred',
        header: 'Cost/100HL',
      },
      {
        accessorKey: 'currency',
        header: 'Curr',
      },
      {
        accessorKey: 'unit',
        header: 'Unit',
      },
      {
        accessorKey: 'uom',
        header: 'Uom',
      },
      {
        accessorKey: 'valid_from',
        header: 'Valid From',
      },
      {
        accessorKey: 'valid_to',
        header: 'Valid To',
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }) =>
          row.original?.status ? (
            <Status
              status={
                String(row.original?.status).toLowerCase() === 'closed' ||
                String(row.original?.status).toLowerCase() === 'completed'
                  ? 'Completed'
                  : row.original?.status
              }
            />
          ) : (
            'N/A'
          ),
      },
      {
        accessorKey: 'logs1',
        header: 'Logs',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={() => {
              setLogsModal({
                open: true,
                logs: row.original?.IntCrStepStatusLogs,
                type: logsHeader,
              });
            }}
          >
            View Logs
          </Button>
        ),
      },
      {
        accessorKey: 'logisticsFpna',
        header: 'Logistics FP&A SME',
      },
      {
        accessorKey: 'fpna_gcc_sme',
        header: 'FP&A GCC SME',
      },
      {
        accessorKey: 'submittedOn',
        header: 'Submitted On',
      },
      {
        accessorKey: 'completedOn',
        header: 'Completed On',
      },
      {
        accessorKey: 'action',
        header: 'Submit',
        Cell: ({ row }) => {
          const status = row.original?.status;
          const country = row.original?.producing_country;
          const access = checkActive(status, country, 'logistics');
          return (
            <Button
              disabled={!access}
              color='dark'
              onClick={() => {
                submitCostChange(
                  row.original?.costPerHL,
                  row?.original?.id,
                  row.original?.sales_org,
                );
              }}
            >
              Submit
            </Button>
          );
        },
      },
    ],
    [handleRefresh, setData],
  );
  const initialState = {
    columnPinning: {
      left: ['logs'],
      right: ['action'],
    },
    sorting: [
      {
        id: 'sku_description',
        desc: false,
      },
    ],
  };
  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        pageSize={10}
        initialState={initialState}
        enableColumnFilter={false}
        enableFilters={false}
        enableExport={true}
        columnFilterDisplayMode={false}
        csvFilename={csvFilename}
      />
    </>
  );
};

export default VLCTable;

import { useContext, useEffect, useMemo, useState } from 'react';
import MantineTable from '../../../common/table/mantine';
import { AdminContext } from '../../../contexts/AdminContext';
import { Tooltip, ActionIcon, Grid } from '@mantine/core';
import EditModal from './editModal';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import RoleModal from './roleAccess';
import withConfirm from '../../../components/common/confirm-dialog';
import { axios } from '../../../utils/axios';

const description = `Are you sure you want to delete user ? This action will revoke all the Accesses of the user`;

const Users = ({ activeTab, confirm, setConfirmLoading }) => {
  const [editModalState, setModalState] = useState({ open: false, user: null });
  const [roleModalState, setRoleModalState] = useState({ open: false, user: null });
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchUsersFromBackend } = useContext(AdminContext);
  useEffect(() => {
    if (activeTab === 'existing') fetch();
  }, [activeTab]);

  const fetch = async () => {
    setLoading(true);
    try {
      const transformedData = await fetchUsersFromBackend();
      setUsers(transformedData);
    } catch (e) {
      console.error('Error fetching data:', e);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async (userID, isPermanentDelete) => {
    setLoading(true);
    setConfirmLoading(true)
    try {
      await axios.delete(`/user/v2/${userID}`, {
        params: {
          force: isPermanentDelete,
        },
      });
    } catch (error) {
    } finally {
      await fetch();
      setLoading(false);
      setConfirmLoading(false)
    }
  };

  const existingUsersColumns = useMemo(
    () => [
      {
        header: 'Actions',
        id: 'actions',
        enableColumnFilter: false,
        enableColumnSorting: false,
        enableColumnActions: false,
        size: 80,
        minSize: 80,
        maxSize: 120,
        Cell: ({ row }) => (
          <Grid>
            <Grid.Col span={6}>
              <Tooltip label='Edit'>
                <ActionIcon onClick={() => setModalState({ open: true, user: row.original })}>
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
            </Grid.Col>
            <Grid.Col span={6}>
              <Tooltip label='Remove all accesses'>
                <ActionIcon
                  color='red'
                  onClick={() =>
                    confirm(
                      (checkbox = {}) => {
                        const isPermanentDelete = checkbox.checkboxStatus || false;;
                        handleDelete(row.original.id, isPermanentDelete);
                      },
                      {
                        title: 'Revoke User Access',
                        description: description,
                        confirmationText: 'Delete',
                        displayCheckbox: true,
                        checkboxLabel: 'Delete User',
                      },
                    )
                  }
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            </Grid.Col>
          </Grid>
        ),
      },
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: false,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableEditing: false,
      },
      {
        header: 'Roles',
        accessorKey: 'roles',
        enableEditing: true,
        Cell: ({ row }) => (
          <Tooltip label='Click to see assigned roles in detail'>
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
              onClick={() => setRoleModalState({ open: true, user: row.original })}
            >
              {row.original.roles}
            </div>
          </Tooltip>
        ),
      },
      {
        header: 'Created on',
        accessorKey: 'created_on',
        enableEditing: false,
      },
      {
        header: 'Created by',
        accessorKey: 'createdBy',
        enableEditing: false,
      },
      {
        header: 'Approved by',
        accessorKey: 'approvedBy',
        enableEditing: false,
      },
    ],
    [users],
  );

  return (
    <>
      {' '}
      <MantineTable
        columns={existingUsersColumns}
        initialData={users}
        unique={'id'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        csvFilename={`existing-users`}
        pageSize={10}
        enableExport={true}
        initialState={{
          sorting: [
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
      />
      {editModalState.open && (
        <EditModal
          state={editModalState}
          fetchUsers={fetch}
          close={() => setModalState({ open: false, user: null })}
        />
      )}
      {roleModalState.open && (
        <RoleModal
          state={roleModalState}
          fetchUsers={fetch}
          close={() => setRoleModalState({ open: false, user: null })}
        />
      )}
    </>
  );
};

export default withConfirm(Users);

import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { showAlert } from '../../../../../utils/alerts';
import FinalizeTable from './table';
import { axios } from '../../../../../utils/axios';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
import { transformData } from './helper';

const FinalizeTPFlow = ({ activeTab, activeSubTab, setLogsModal }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { crNumber } = useParams();
  const { getIntCrUserRoleMappings, userRoleMappings } = useContext(CostingRequestsContext);

  useEffect(() => {
    const fetchMappings = async () => {
      await getIntCrUserRoleMappings();
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (
      activeTab === 'tpSetup' &&
      activeSubTab === 'finalizeTpFlow' &&
      userRoleMappings.length > 0
    ) {
      fetch();
    }
  }, [activeTab, activeSubTab, userRoleMappings]);

  const getAllowedCountry = (type) => {
    try {
      if (type === 'logistics') {
        let logistics = userRoleMappings
          .filter(
            (role) =>
              role.country && role.is_active && role.int_role?.alias === 'logistics_fpna_analyst',
          )
          .map((role) => role.country);

        logistics = [...new Set(logistics)];
        return logistics;
      }

      let countries = userRoleMappings
        .filter((role) => role.country && role.is_active && role.int_role?.name === 'GCC FP&A')
        .map((role) => role.country);

      countries = [...new Set(countries)];
      return countries;
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  const checkAccess = (status, country, type) => {
    const allowedCountries = getAllowedCountry(type);
    const accessAllowed =
      String(status).toLowerCase() === 'in progress' && allowedCountries.includes(country);
    return accessAllowed;
  };

  const handleData = (data) => {
    const transformedData = data.flatMap((entry) =>
      entry.IntCrTpFinalizations.map((log) => ({
        ...entry,
        IntCrTpFinalizations: [log], // Embed the single log back into the structure
      })),
    );
    return transformedData;
  };

  const fetch = async () => {
    setLoading(true);
    try {
      const api = `/costing-request/step/finalize-tp/${crNumber}`;
      const { data } = await axios.get(api);
      const allData = handleData(data?.data);
      const apiData = transformData(allData);
      setData(apiData);
    } catch (e) {
      showAlert('', 'error');
    } finally {
      setLoading(false);
    }
  };
  const submitLogisticsChange = async (id, log) => {
    setLoading(true);
    const payload = {
      id: id,
      stg_log: String(log),
    };
    try {
      const res = await axios.post(`/costing-request/step/input-tp/${crNumber}`, payload);
      if (res?.data?.success) {
        showAlert({ message: 'Std Logistics Cost submitted successfully! ' }, 'success');
      }
    } catch (e) {
      showAlert({ message: 'Std Logistics Cost submission failed, Please check Logs ' }, 'error');
    } finally {
      await fetch();
      setLoading(false);
    }
  };
  const handleChange = (val) => {
    setData((prevData) => prevData.map((ele) => (ele.id === val.id ? { ...ele, ...val } : ele)));
  };
  const finalizeTp = async (id) => {
    setLoading(true);
    try {
      const api = `/costing-request/step/finalize-tp/${crNumber}`;
      const payload = { id: id };
      const res = await axios.post(api, payload);
      if (res?.data?.success) {
        showAlert({ message: 'Finalization of TP success' }, 'success');
      } else {
        showAlert({ message: 'Finalization of TP failed, Please check logs' }, 'error');
      }
    } catch (e) {
      showAlert({ message: 'Finalization of TP failed, Please check logs' }, 'error');
    } finally {
      await fetch();
      setLoading(false);
    }
  };
  const initialState = {
    columnPinning: {
      left: [],
      right: ['logs'],
    },
    sorting: [{}],
  };
  return (
    <>
      <FinalizeTable
        loading={loading}
        data={data}
        initialState={initialState}
        handleChange={handleChange}
        setLogsModal={setLogsModal}
        finalizeTp={finalizeTp}
        checkAccess={checkAccess}
        submitLogisticsChange={submitLogisticsChange}
      />
    </>
  );
};

export default FinalizeTPFlow;

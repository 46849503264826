import React from 'react';
import { Modal, Grid, Group, Text } from '@mantine/core';

import { InfoCircle } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../routes';

import styled from '@emotion/styled';

const StyledCR = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
`;

const DetailsContainer = styled.div`
  display: flex;
  font-size: 12px;
  flex-direction: column;
  gap: 12px;
`;

const Details = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
  color: #e5b611;
`;

export const DuplicateWarning = ({ duplicateWarning, setDuplicateWarning }) => {
  const { open, crNumber } = duplicateWarning;
  const handleClose = () => {
    setDuplicateWarning({
      open: false,
      crNumber: null,
    });
  };

  return (
    <>
      <Modal
        className='duplicatePlant-modal'
        opened={open}
        onClose={() => handleClose()}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='duplicatePlant-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <DetailsContainer>
                <Text size='sm'>
                  A costing request already exists for this combination. Please find details below.
                </Text>

                <Details>
                  <StyledCR size='sm' className='bold'>
                    Duplicate CR Number:
                  </StyledCR>

                  <StyledLink
                    size='sm'
                    target='_blank'
                    to={routes.costingRequests(crNumber).overview}
                  >
                    {crNumber}
                  </StyledLink>
                </Details>
              </DetailsContainer>
            </Group>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};

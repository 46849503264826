import { useMemo, useState } from 'react';
import MantineTable from '../../../../../common/table/mantine';
import { Button } from '@mantine/core';
import { Refresh, IconReload } from '@tabler/icons-react';
import { Status } from '../../../styles';
import moment from 'moment';

const AvicTable = ({ handleRefresh, data, loading, setLogsModal, getLogsData }) => {
  const callLogsData = async (id) => {
    return await getLogsData(id);
  };

  const columns = useMemo(
    () => [
      {
        id: 'action',
        header: 'Action',
        Cell: ({ row }) => {
          return (
            <Button
              disabled={!row?.original?.accessAllowed}
              variant='light'
              color='gray.0'
              size='md'
              onClick={() =>
                handleRefresh(
                  row.original?.distribution_channel,
                  row.original?.condition_type,
                  row.original?.payloadId,
                  row.original?.sales_org,
                )
              }
            >
              <IconReload size={16} color='black' />
            </Button>
          );
        },
      },
      {
        accessorKey: 'sku',
        header: 'SKU No',
      },
      {
        accessorKey: 'sku_desc',
        header: 'SKU Description',
      },
      {
        accessorKey: 'sales_org',
        header: 'Sales Org',
      },
      {
        accessorKey: 'distribution_channel',
        header: 'Distribution Channel',
      },
      {
        accessorKey: 'condition_type',
        header: 'Condn Type',
      },
      {
        accessorKey: 'key_combination',
        header: 'Key Combn',
      },
      {
        accessorKey: 'costPerHL',
        header: 'Cost/HL',
      },
      {
        accessorKey: 'costPerHundred',
        header: 'Cost/100HL',
      },
      {
        accessorKey: 'currency',
        header: 'Curr',
      },
      {
        accessorKey: 'container_unit',
        header: 'Unit',
      },
      {
        accessorKey: 'uom',
        header: 'Uom',
      },
      {
        accessorKey: 'valid_from',
        header: 'Valid From',
      },
      {
        accessorKey: 'valid_to',
        header: 'Valid To',
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }) =>
          row.original?.status ? <Status status={row.original?.status} /> : 'N/A',
      },
      {
        id: 'logs',
        header: 'Logs',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={async () => {
              const logs = await callLogsData(row.original?.IntCrConditionRecordLogs?.[0]?.id);
              setLogsModal({
                open: true,
                logs: logs,
                type: 'Avic Setup',
              });
            }}
          >
            View Logs
          </Button>
        ),
      },
      {
        accessorKey: 'fpnaName',
        header: 'FP&A GCC SME',
      },
      {
        accessorKey: 'completedOn',
        header: 'Completed On',
      },
    ],
    [handleRefresh],
  );
  const initialState = {
    columnPinning: {
      left: ['action', 'sku', 'sku_desc'],
      right: [],
    },
    sorting: [
      {
        id: 'sku_desc',
        desc: false,
      },
    ],
  };
  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        pageSize={10}
        initialState={initialState}
        enableColumnFilter={false}
        enableFilters={false}
        enableExport={true}
        columnFilterDisplayMode={false}
        csvFilename={`cost_condtion_creattion_AVIC_${moment().format('YYYY-MM-DD')}`}
      />
    </>
  );
};

export default AvicTable;
